<template>
  <div
    class="w-full flex rounded-xl shadow items-center p-2 duration-100 mobile:flex-col"
    :class="isSelected ? 'bg-green text-white' : ' bg-offwhite'"
  >
    <div class="w-3/12 mobile:w-full flex justify-center">
      <h4>{{ data.profile_name }}</h4>
    </div>
    <div class="w-3/12 mobile:w-full flex justify-center mobile:hidden">
      <img
        :src="imageUrl"
        class="h-12 w-12 rounded-full p-1"
        :class="isSelected ? 'bg-white' : ''"
      />
    </div>
    <div
      class="w-3/12 mobile:w-full flex justify-center"
      :class="isSelected ? 'font-semibold' : ''"
    >
      {{ getUserRole(data.permissions) }}
    </div>
    <div class="w-3/12 items-center justify-center flex">
      <button
        v-if="canEdit"
        @click="toggleStatus"
        class="duration-100 p-2 leading-none text-white"
        :class="
          isSelected
            ? 'bg-danger  hover:bg-dangerdark hover:shadow-dangeroutline'
            : 'bg-green hover:bg-darkgreen hover:shadow-outline'
        "
      >
        <div
          class="transform duration-150"
          :class="isSelected ? 'rotate-45' : ''"
        >
          <span class="typcn typcn-plus text-2xl leading-none transform"></span>
        </div>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProjectWorkerCard",
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      }
    },
    selected: Boolean,
    canEdit: Boolean
  },
  data() {
    return {
      isSelected: false
    };
  },
  computed: {
    imageUrl() {
      if (Object.keys(this.data.image).length > 0) {
        return process.env.VUE_APP_MEDIA_URL + this.data.image.image;
      } else {
        return "/favicon.ico";
      }
    }
  },
  methods: {
    toggleStatus() {
      this.isSelected = !this.isSelected;
      this.$emit("toggleSelect", this.data.id);
    }
  },
  mounted() {
    this.isSelected = this.selected;
  }
};
</script>
