<template>
  <div
    v-if="pricingLoaded"
    class="flex flex-col p-4 w-full mobile:items-center bg-white rounded-b-md hard-shadow mb-5"
  >
    <div>
      <div class="flex flex-row">
        <div class="flex flex-col w-3/12">
          <span>Algus kuupäev</span>
          <date-picker
            class="project-add-task-date-selector w-full"
            :language="ee"
            :format="formatDate"
            :monday-first="true"
            v-model="startDate"
          ></date-picker>
        </div>
        <div class="flex flex-col w-3/12">
          <span>Lõpp kuupäev</span>
          <date-picker
            class="project-add-task-date-selector w-full"
            :language="ee"
            :format="formatDate"
            :monday-first="true"
            v-model="endDate"
          ></date-picker>
        </div>
      </div>
    </div>
    <div v-for="task in pricing.completed_tasks" :key="task.id">
      <div class="flex flex-row items-center">
        <h2 class="mr-3">Ülesanne: {{ task.title }}</h2>
        <span> {{ task.price }} €</span>
      </div>
      <div
        v-for="(times, worker) in task.task_times"
        :key="worker"
        class="p-1 pl-3"
      >
        {{ worker }}
        <div v-if="times.length === 0">
          <span class="p-1 pl-3">Ajamõõtmine puudub</span>
        </div>
        <div v-for="time in times" :key="time.start" class="p-1 pl-3">
          {{ formatTime(time.start, time.end) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RequestHandler from "@/assets/mixins/RequestHandler";
import DatePicker from "vuejs-datepicker";
import moment from "moment";
import { ee } from "vuejs-datepicker/dist/locale";

export default {
  name: "ProjectPricing",
  components: { DatePicker },
  props: {
    userRole: {
      type: String,
      default: ""
    },
    draftUuid: {
      type: String,
      default: ""
    }
  },
  mixins: [RequestHandler],
  data() {
    return {
      pricing: null,
      startDate: null,
      endDate: null,
      ee: ee,
      pricingLoaded: false
    };
  },
  methods: {
    retrieveDraftPricing() {
      this.pricingLoaded = false;
      this.apiRequest(
        "notes/draft/" + this.$route.params.projectId + "/pricing/",
        "get",
        true
      ).then(res => {
        this.pricing = res.data;
        this.pricingLoaded = true;
      });
    },
    formatDate(date) {
      return moment(date).format("DD.MM.YYYY");
    },
    formatTime(start, end) {
      if (end === null) {
        return "Jooksev";
      }
      let duration = moment.duration(moment(end).diff(moment(start)));
      return duration.get("hour") + ":" + duration.get("minute") + " h";
    }
  },
  mounted() {
    this.retrieveDraftPricing();
  }
};
</script>
