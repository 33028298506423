<template>
  <div
    class="flex flex-col w-full mobile:items-center bg-white rounded-gwdhalf mb-5"
  >
    <div
      v-if="dataLoaded && workerListLoaded"
      class="w-full flex flex-col rounded-b-md"
    >
      <project-worker-selector
        :workers="data.workers"
        :company-workers="
          companyWorkers.results.filter(x =>
            ['R3', 'R4', 'R5'].includes(x.permissions)
          )
        "
        :can-edit="['R0', 'R1', 'R2', 'R3'].includes(userRole)"
        @workersUpdated="handleWorkerUpdate"
      ></project-worker-selector>
    </div>
    <div
      v-if="workersChanged || managersChanged"
      class="w-full flex justify-center"
    >
      <button @click="saveChanges" class="new-button-green">
        <span class="typcn typcn-tick icon"></span>
        <span class="label">Salvesta muudatused</span>
      </button>
    </div>

    <div v-if="dataLoaded" class="hidden">
      <span class="text-bold text-xl"> Projektile lisatud kliendid: </span>
      <div v-if="data.clients.length === 0">
        <span>Projektil ei ole lisatud kliente</span>
      </div>
      <div v-if="data.clients.length > 0">
        <div v-for="client in data.clients" :key="client.id" class="p-2">
          {{ client.display_username }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RequestHandler from "@/assets/mixins/RequestHandler";
import ProjectWorkerSelector from "@/components/project/ProjectWorkerSelector";
import isEqual from "lodash/isEqual";

export default {
  name: "ProjectWorkers",
  components: { ProjectWorkerSelector },
  mixins: [RequestHandler],

  props: {
    draftUuid: {
      type: String,
      default: ""
    },
    userRole: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      searchComplete: false,
      error: false,
      data: [],
      dataLoaded: false,
      workerListLoaded: false,
      companyWorkers: [],
      addWorkersActive: false,
      addManagersActive: false,
      newWorkers: [],
      newManagers: [],
      workersChanged: false,
      managersChanged: false
    };
  },
  methods: {
    loadWorkers() {
      this.$store.state.loading = true;
      this.dataLoaded = false;
      this.data = [];
      this.apiRequest(
        "notes/draft/" + this.draftUuid + "/workers/",
        "get",
        true
      ).then(res => {
        this.data = res.data;
        this.newWorkers = res.data.workers.map(x => x.id);
        this.newManagers = res.data.managers.map(x => x.id);
        this.$store.state.loading = false;
        this.dataLoaded = true;
      });
    },
    retrieveWorkers() {
      this.apiRequest(
        "company/" +
          this.$store.state.companyData.activeCompany.uuid +
          "/workers/?all=True",
        "get",
        true
      ).then(res => {
        this.companyWorkers = res.data;
        this.workerListLoaded = true;
      });
    },
    handleManagerUpdate(e) {
      this.newManagers = e;
    },
    handleWorkerUpdate(e) {
      this.newWorkers = e;
    },
    saveChanges() {
      this.apiRequest(
        "notes/draft/" + this.draftUuid + "/workers/add/",
        "patch",
        true,
        { workers: this.newWorkers, managers: this.newManagers }
      ).then(res => {
        if (res.status === 200) {
          this.newWorkers = [];
          this.newManagers = [];
          this.loadWorkers();
        }
      });
    }
  },
  watch: {
    newWorkers() {
      if (this.dataLoaded) {
        const array2Sorted = this.newWorkers.slice().sort();
        const workersMap = this.data.workers
          .map(x => x.id)
          .slice()
          .sort();
        this.workersChanged = !isEqual(array2Sorted, workersMap);
      }
    },
    newManagers() {
      if (this.dataLoaded) {
        const array2Sorted = this.newManagers.slice().sort();
        const managersIDMap = this.data.managers
          .map(x => x.id)
          .slice()
          .sort();
        this.managersChanged = !isEqual(array2Sorted, managersIDMap);
      }
    }
  },
  mounted() {
    this.loadWorkers();
    this.retrieveWorkers();
  }
};
</script>
