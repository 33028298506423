<template>
  <a
    :href="downloadLink"
    download
    class="w-full mb-1"
    target="_blank"
    v-if="urlLoaded"
  >
    <div
      class="flex flex-row p-3 bg-offwhite rounded-xl items-center duration-100"
      :class="hover ? 'bg-offerwhite shadow-outline' : 'bg-offwhite'"
      @mouseover="hover = true"
      @mouseleave="hover = false"
    >
      <div class="flex w-2/12 items-center justify-center">
        <div class="flex w-8 h-8">
          <img class="icon" :src="parseFileTypeIcon" />
        </div>
      </div>
      <div class="flex w-8/12 justify-center items-center">
        <span class="text-sm truncate">{{ file.file_name }}</span>
      </div>
      <div class="flex w-2/12">
        <span
          class="typcn typcn-download text-2xl leading-none"
          :class="hover ? 'text-green' : ''"
        ></span>
      </div>
    </div>
  </a>
</template>

<script>
import FileTypeParser from "@/assets/mixins/FileTypeParser";
import RequestHandler from "@/assets/mixins/RequestHandler";

export default {
  name: "CommentFile",
  props: {
    file: {
      type: Object,
      default: () => {
        return {};
      }
    },
    company: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      downloadLink: "",
      urlLoaded: false,
      hover: false,
      nameFaded: false,
      downloadFaded: true
    };
  },
  mixins: [FileTypeParser, RequestHandler],
  methods: {
    downloadFile() {
      if (this.file) {
        this.downloadLink = "";
        this.urlLoaded = false;
        this.apiRequest(
          "files/private/" +
            (this.company !== null &&
            this.company !== "" &&
            this.company !== undefined
              ? this.company
              : this.$store.state.companyData.activeCompany.uuid) +
            "/" +
            this.file.id +
            "/?type=file" +
            (this.$store.state.companyData.isClient
              ? "&project_uuid=" + this.$route.params.projectId
              : ""),
          "get",
          true
        ).then(res => {
          this.downloadLink = res.data.file_url;
          this.urlLoaded = true;
        });
      }
    }
  },
  computed: {
    parseFileTypeIcon() {
      return this.parseFileType(this.file);
    }
  },
  mounted() {
    this.downloadFile();
  }
};
</script>
