<template>
  <div
    class="flex flex-col mobile:w-full w-2/12 bg-white rounded-l-gwdhalf text-darkgrey mobile:rounded-none mobile:rounded-t-gwdhalf mobile:rounded-b-xl"
  >
    <div class="hidden mobile:flex self-center">
      <h4>Valitud kanal</h4>
    </div>
    <div class="flex flex-col mobile:flex-row">
      <div
        v-if="['R0', 'R1', 'R2', 'R3'].includes(userRole)"
        class="w-full p-3 cursor-pointer duration-100 rounded-tl-gwdhalf mobile:w-1/3 mobile:justify-center mobile:text-center mobile:items-center flex mobile:border-b-2"
        :class="
          selectedChannel === 1
            ? 'border-r-4 mobile:border-r-0 border-green hover:bg-white text-green'
            : 'hover:bg-darkgreen hover:text-white border-offwhite'
        "
        @click="selectChannel(1)"
      >
        <span class="font-medium text-lg">Projekti juhid</span>
      </div>

      <div
        v-if="['R0', 'R1', 'R2', 'R3', 'R4', 'R5'].includes(userRole)"
        class="w-full p-3 cursor-pointer duration-100 mobile:justify-center mobile:text-center mobile:items-center flex mobile:border-b-2"
        @click="selectChannel(2)"
        :class="[
          selectedChannel === 2
            ? 'border-r-4 mobile:border-r-0 border-green hover:bg-white text-green'
            : 'hover:bg-darkgreen hover:text-white border-offwhite',
          ['R4', 'R5'].includes(userRole) ? 'mobile:w-full' : 'mobile:w-1/3'
        ]"
      >
        <span class="font-medium text-lg">Meeskond</span>
      </div>

      <div
        v-if="
          ['R0', 'R1', 'R2'].includes(userRole) ||
            $store.state.companyData.isClient
        "
        class="p-3 cursor-pointer duration-100 mobile:justify-center mobile:text-center flex mobile:items-center mobile:border-b-2"
        @click="selectChannel(3)"
        :class="[
          selectedChannel === 3
            ? 'border-r-4 mobile:border-r-0 border-green hover:bg-white text-green'
            : 'hover:bg-darkgreen hover:text-white border-offwhite',
          $store.state.companyData.isClient
            ? 'rounded-tl-gwdhalf w-full'
            : ' mobile:w-1/3 w-full'
        ]"
      >
        <span class="font-medium text-lg">Klient</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    userRole: {
      type: String,
      default: ""
    },
    selectedChannel: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      isExpanded: null
    };
  },
  mounted() {
    this.isExpanded = true;
  },
  methods: {
    selectChannel(channel) {
      this.$emit("channelSelected", channel);
      if (window.innerWidth <= 992) {
        this.isExpanded = false;
      }
    },
    expandSidebar() {
      this.isExpanded = true;
    },
    closeSidebar() {
      this.isExpanded = false;
    }
  }
};
</script>
