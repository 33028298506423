<template>
  <div class="modal">
    <div class="modal-body modal-medium">
      <span class="text-2xl mb-4">Kliendina kasutamine piiratud</span>
      <div class="flex flex-col mb-4">
        <span>Paketi kasutusaeg on lõppenud.</span>
        <span
          >Palun võtke ettevõtte omanikuga ühendust, ning paluge seda uuendada.
        </span>
      </div>
      <div class="flex">
        <button
          class="new-button-danger p-2"
          @click="$router.push('/client/projects/')"
        >
          <span class="label">Tagasi</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ClientCannotAccess"
};
</script>
