<template>
  <div class="modal">
    <div class="modal-body modal-small">
      <div class="modal-content">
        <div class="w-full mx-auto flex flex-col items-center p-2">
          <h3 class="mb-2">Kinnitus</h3>
          <span>{{ text }}</span>
        </div>
      </div>
      <div class="modal-bottom-bar">
        <button @click="confirmDelete()" class="new-button-danger mr-3">
          <span class="icon typcn typcn-tick"></span>
          <span class="label">Jah</span>
        </button>
        <button @click="closeModal()" class="new-button-green">
          <span class="icon typcn typcn-times"></span>
          <span class="label">Ei</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: "Olete kindel, et soovite kustutada?"
    }
  },
  methods: {
    confirmDelete() {
      this.$emit("confirmDelete");
      this.closeModal();
    },
    closeModal() {
      this.$emit("closeModal");
    }
  }
};
</script>
