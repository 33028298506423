<template>
  <div
    class="flex bg-white rounded-gwdhalf items-center flex-col cursor-pointer duration-100"
    @click="viewOffer"
  >
    <div
      class="flex w-full justify-center mobile:justify-between items-center top-row py-3 hover:bg-offwhite hover:bg-opacity-50 px-3 mobile:flex-wrap mobile:px-0"
    >
      <div class="w-3/12 flex justify-start mobile:w-6/12 items-center">
        <h4>Pakkumine #{{ offer.offer_index }}</h4>
        <div
          class="mobile:flex hidden"
          v-html="parseDraftOfferStatusNoText(offer.status)"
        ></div>
      </div>
      <div class="w-3/12 mobile:justify-end flex items-center mobile:w-6/12">
        <span
          class="bg-green text-white rounded-gwdfull shadow w-1 h-8 justify-center flex text-xl mr-2"
        ></span>
        <h4 class="font-medium">{{ offer.price }}€</h4>
      </div>
      <div class="w-3/12 flex justify-start mobile:hidden">
        {{ moment(offer.created_at).format("DD.MM.YYYY") }}
      </div>
      <div class="flex relative w-3/12 mobile:hidden">
        <div v-html="parseDraftOfferStatus(offer.status)"></div>
      </div>
    </div>
    <div class="bg-offwhite rounded-full w-full h-1"></div>
  </div>
</template>

<script>
import StatusParser from "@/assets/mixins/StatusParser";
import RequestHandler from "@/assets/mixins/RequestHandler";
import moment from "moment";

export default {
  props: {
    offer: {
      type: Object,
      default: () => {
        return {};
      }
    },
    draft: {
      type: Object,
      default: () => {
        return {};
      }
    },
    activeOfferId: {
      type: Number,
      default: null
    },
    userRole: {
      type: String,
      default: "R6"
    }
  },
  data() {
    return {
      moment: moment
    };
  },
  methods: {
    viewOffer() {
      this.$store.dispatch("offerViewer/openOfferModal", {
        offer: this.offer,
        draft: this.draft
      });
    }
  },
  mixins: [StatusParser, RequestHandler]
};
</script>
