<template>
  <div
    class="bg-white flex flex-col p-2 justify-start text-center shadow w-full h-full"
  >
    <div class="mobile:flex hidden justify-end" id="fileSidebarContent">
      <button @click="closeSidebar" class="new-button-danger">
        <span class="icon typcn typcn-times"></span>
        <span class="label">Sulge</span>
      </button>
    </div>
    <div class="flex flex-col items-center" v-if="!activeFile">
      <span class="typcn typcn-info-large text-6xl"></span>
      <span>Et näha faili detaile, valige vasakult fail</span>
    </div>
    <file-sidebar-file-view
      v-if="activeFile"
      :active-file="activeFile"
      :show-delete="showDelete"
      :type="type"
      :draft="draft"
      :company="company"
      @fileDeleted="handleFileDeleted"
    ></file-sidebar-file-view>
    <div
      class="flex mt-auto justify-center flex-col"
      v-if="Object.keys(storageData).length > 0"
    >
      <div class="flex justify-center w-full">
        <div class="bar bg-offwhite w-full h-3 rounded-full">
          <div
            class="bg-green rounded-full h-3"
            :style="{
              width: (storageData.used / storageData.total) * 100 + '%'
            }"
          ></div>
        </div>
      </div>
      <div class="flex justify-center">
        {{ usedFileSize }} / {{ totalFileSize }}
      </div>
    </div>
  </div>
</template>

<script>
import RequestHandler from "@/assets/mixins/RequestHandler";
import FileSidebarFileView from "@/components/company/files/FileSidebarFileView";
import FileSizeHumanizer from "@/assets/mixins/FileSizeHumanizer";
export default {
  props: {
    activeFile: { type: Object, default: null },
    storageData: {
      type: Object,
      default: () => {
        return {};
      }
    },
    userRole: {
      type: String,
      default: "R5"
    },
    showDelete: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: () => {
        return "file";
      }
    },
    draft: {
      type: String,
      default: null
    },
    company: {
      type: String,
      default: null
    }
  },
  mixins: [RequestHandler, FileSizeHumanizer],
  components: {
    FileSidebarFileView
  },
  data() {
    return {};
  },
  methods: {
    handleFileDeleted() {
      this.$emit("fileDeleted");
    },
    closeSidebar() {
      this.$emit("closeSidebar");
    }
  },
  mounted() {
    setTimeout(function() {
      document.getElementById("fileSidebarContent").scrollIntoView();
    }, 150);
  },
  computed: {
    usedFileSize() {
      return this.humanizeSize(this.storageData.used);
    },
    totalFileSize() {
      return this.humanizeSize(this.storageData.total);
    }
  }
};
</script>
