<template>
  <div class="flex w-full">
    <div class="flex flex-col w-full" v-if="weekInited">
      <div class="flex items-center justify-center">
        <span
          class="typcn typcn-arrow-left text-3xl hover:text-green cursor-pointer"
          @click="initWeek(-1)"
        ></span>
        <h3 class="mx-3">{{ startOfWeek.format("W") }}. nädal</h3>
        <span
          class="typcn typcn-arrow-right text-3xl hover:text-green cursor-pointer"
          @click="initWeek(1)"
        ></span>
      </div>

      <div
        class="grid grid-cols-7 mobile:grid-cols-1 w-full gap-x-1"
        v-if="tasksInited"
      >
        <day
          :day="item"
          v-for="item in weekArray"
          :key="item.index"
          :company="company"
          @openAddTaskView="openAddTaskView"
        ></day>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Day from "./weekly/Day";
import RequestHandler from "@/assets/mixins/RequestHandler";

export default {
  props: {
    company: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      currentWeek: null,
      startOfWeek: null,
      dbFormat: "YYYY-MM-DD[T]HH:mm:ssZ",
      tasksInited: false,
      weekInited: false,
      weekArray: [],
      moment: moment
    };
  },
  components: {
    Day
  },
  mixins: [RequestHandler],
  methods: {
    initWeek(change) {
      if (change > 0) {
        this.startOfWeek = moment(this.startOfWeek)
          .add(change, "week")
          .startOf("week");
      } else if (change < 0) {
        this.startOfWeek = moment(this.startOfWeek)
          .subtract(1, "week")
          .startOf("week");
      } else {
        if (!this.startOfWeek) {
          this.startOfWeek = moment(this.currentDate).startOf("week");
        } else {
          this.startOfWeek = moment(this.startOfWeek).startOf("week");
        }
      }
      this.weekArray = [];
      for (let i = 0; i < 7; i++) {
        let dayObject = {};
        dayObject.index = i;
        dayObject.date = moment(this.startOfWeek).add(i, "day");
        dayObject.tasks = [];
        dayObject.hovered = false;
        this.weekArray.push(dayObject);
      }
      this.initTasks();

      this.weekInited = true;
    },
    initTasks() {
      this.tasksInited = false;
      let url;
      if (this.$store.state.companyData.isClient) {
        url =
          "calendar/" +
          this.company +
          "/" +
          this.$route.params.projectId +
          "/tasks/client/?period=week&start_time=" +
          moment(this.startOfWeek).format("YYYY-MM-DD");
      } else {
        url =
          "calendar/" +
          this.$store.state.companyData.activeCompany.uuid +
          "/" +
          this.$route.params.projectId +
          "/tasks/?period=week&start_time=" +
          moment(this.startOfWeek).format("YYYY-MM-DD");
      }
      this.apiRequest(url, "get", true).then(res => {
        for (let i = 0; i < res.data.length; i++) {
          const weekDay = this.weekArray.find(item =>
            moment(item.date).isSame(res.data[i].start_time, "day")
          );
          if (weekDay) {
            weekDay.tasks.push(res.data[i]);
          }
        }
        this.sortTasks();
        this.tasksInited = true;
      });
    },
    openAddTaskView(date) {
      this.$emit("addTask", date);
    },
    sortTasks() {
      for (let i = 0; i < this.weekArray.length; i++) {
        this.weekArray[i].tasks.sort((a, b) => {
          if (moment(a.start_time).unix() > moment(b.start_time).unix()) {
            return 1;
          } else if (
            moment(a.start_time).unix() < moment(b.start_time).unix()
          ) {
            return -1;
          } else {
            return 0;
          }
        });
      }
    }
  },

  mounted() {
    this.currentWeek = moment();
  }
};
</script>
