<template>
  <div class="flex flex-col w-full bg-white">
    <div class="flex mobile:flex-col">
      <div class="w-5/12 mobile:w-full flex flex-col pr-2">
        <div class="flex flex-col mb-2">
          <div class="flex flex-row">
            <h3>Klient</h3>
          </div>
        </div>
        <div
          class="flex flex-col py-1 justify-center duration-200 mobile:w-full mr-3"
          v-if="editMode"
        >
          <div class="flex mb-2 flex-col">
            <h4>Nimi</h4>
            <input
              type="text"
              v-model="editables.client.name"
              class="bg-offwhite flex-grow shadow focus:shadow-outline mb-3"
            />
            <h4>Meiliaadress</h4>
            <input
              type="text"
              v-model="editables.client.email"
              class="bg-offwhite flex-grow shadow focus:shadow-outline mb-3"
            />
            <h4>Telefoninumber</h4>
            <div class="flex flex-row">
              <select
                class="w-4/12 mobile:w-3/12 mr-1 shadow focus:shadow-outline"
              >
                <option value="+372">+372</option>
              </select>
              <input
                type="text"
                v-model="editables.client.telephone_number"
                class="w-8/12 bg-offwhite flex-grow mobile:mr-0 shadow focus:shadow-outline mb-3"
              />
            </div>

            <h4 class="mb-2">Aadress</h4>
            <div class="flex flex-col items-start" v-if="!editLocation">
              <span class="mb-2" v-if="editables.client.address">{{
                editables.client.address.long_address
                  ? editables.client.address.long_address
                  : editables.client.address.ipikkaadress
                  ? editables.client.address.ipikkaadress
                  : "-"
              }}</span>
              <button @click="editLocation = true" class="alt-button-pending">
                <span class="icon typcn typcn-edit"></span>
                <span class="label">Muuda</span>
              </button>
            </div>
            <div class="flex flex-col items-start" v-else>
              <location-inaddress
                :text="'Kliendi aadress'"
                class="w-full mb-2"
                @locationChanged="handleClientLocationChange"
              >
              </location-inaddress>
              <button @click="editLocation = false" class="alt-button-danger">
                <span class="typcn typcn-times icon"></span>
                <span class="label">Tühista</span>
              </button>
            </div>
          </div>
        </div>
        <div class="flex flex-col" v-if="!editMode && data.client !== null">
          <div class="flex flex-col justify-between items-center">
            <div class="flex items-center w-full">
              <router-link :to="`/clients/${data.client.id}`">
                <div class="flex justify-center items-center mr-2">
                  <img
                    :src="profileImage"
                    class="h-10 w-10 rounded-full hover:shadow-outline"
                    v-if="profileImage"
                  />
                  <button class="square-button-green" v-if="!profileImage">
                    <span class="typcn typcn-user h-5 w-5"></span>
                  </button></div
              ></router-link>
              <span
                class="font-medium hover:text-green cursor-pointer truncate"
                @click="$router.push(`/clients/${data.client.id}`)"
              >
                {{
                  data.client.is_company
                    ? data.client.name
                    : data.client.profile
                    ? data.client.profile.display_username
                    : data.client.name
                }}</span
              >
              <div
                class="flex items-center bg-offwhite rounded-full shadow pl-2 ml-auto"
                :class="
                  data.client.profile
                    ? ''
                    : data.client.join_request_sent
                    ? ''
                    : 'hover:bg-offerwhite cursor-pointer'
                "
                @click="
                  data.client.profile || data.client.join_request_sent
                    ? ''
                    : (connectAccountModalActive = true)
                "
              >
                <img src="/favicon.ico" class="h-6 w-6" />
                <span
                  class="flex items-center justify-center ml-2 border-2 rounded-full p-1"
                  :class="
                    data.client.profile
                      ? 'bg-green border-green'
                      : data.client.join_request_sent
                      ? 'bg-attention border-attention'
                      : 'bg-danger border-danger'
                  "
                  :title="
                    data.client.profile
                      ? 'Gewodo kontoga ühendatud!'
                      : data.client.join_request_sent
                      ? 'Ühendamiskutse saadetud!'
                      : 'Gewodo kontoga ühendamata!'
                  "
                >
                  <span
                    class="typcn text-white leading-none"
                    :class="
                      data.client.profile
                        ? 'typcn-tick'
                        : data.client.join_request_sent
                        ? 'typcn-download'
                        : 'typcn-times'
                    "
                  ></span>
                </span>
              </div>
            </div>
            <div class="flex h-1 bg-offwhite w-full rounded-xl mt-2 mb-2"></div>
            <div class="flex">
              <a
                :href="'tel:' + data.client.telephone_number"
                v-if="data.client.telephone_number"
                class="mobile:flex hidden"
              >
                <button class="square-button-green mr-2 mobile:mr-4">
                  <span class="typcn typcn-phone"></span>
                </button>
              </a>
              <a
                :href="'mailto:' + data.client.email"
                v-if="data.client.email"
                class="mobile:flex hidden"
              >
                <button class="square-button-green mobile:mr-4">
                  <span class="typcn typcn-mail"></span>
                </button>
              </a>
              <button
                class="square-button-pending mobile:flex desktop:hidden medium:hidden"
                @click="infoCardActive = !infoCardActive"
              >
                <span class="typcn typcn-info-large"></span>
              </button>
            </div>
          </div>
          <div
            class="flex flex-col mb-3"
            v-if="!editMode && (screenW > 993 || infoCardActive)"
          >
            <div
              class="flex items-center mb-2"
              v-if="data.client.telephone_number"
            >
              <a
                :href="'tel:' + data.client.telephone_number"
                class="mobile:hidden flex"
              >
                <button class="square-button-green mr-2 mobile:mr-4 h-8 w-8">
                  <span class="typcn typcn-phone"></span>
                </button>
              </a>
              <span>{{ data.client.telephone_number }}</span>
            </div>
            <div class="flex items-center mb-2" v-if="data.client.email">
              <a
                :href="'mailto:' + data.client.email"
                class="mobile:hidden flex"
              >
                <button class="square-button-green mr-2 mobile:mr-4 h-8 w-8">
                  <span class="typcn typcn-mail"></span>
                </button>
              </a>
              <span>{{ data.client.email }}</span>
            </div>

            <div class="flex items-center" v-if="data.client.address">
              <a
                :href="
                  `https://maps.google.com/?q=${data.client.address.latitude},${data.client.address.longitude}`
                "
                target="_blank"
              >
                <button
                  class="square-button-green w-8 h-8 mr-2 hover:square-button-active"
                >
                  <span class="icon"
                    ><img src="/bc21/placeholder.svg" class=" h-5 w-5"
                  /></span>
                </button>
              </a>
              <span class="truncate">{{
                data.client.address.long_address
              }}</span>
            </div>
            <div
              v-if="data.client.profile !== null"
              class="flex flex-col"
            ></div>
          </div>
          <div class="flex flex-col" v-if="!editMode && data.client === null">
            <span>Kliendi andmed puuduvad</span>
          </div>
        </div>
      </div>
      <div class="w-7/12 mobile:w-full flex flex-col pl-2 mobile:pl-0">
        <div class="flex flex-row items-center">
          <h3 class="mr-2">Kirjeldus</h3>
        </div>
        <p v-if="!editMode" class="break-words">
          {{ data.details.length > 0 ? data.details : "-" }}
        </p>
        <div v-if="editMode" class="w-full mt-2">
          <textarea
            v-model="editables.details"
            :rows="data.details.length / 60 > 3 ? data.details.length / 60 : 3"
            class="w-full"
          ></textarea>
        </div>
      </div>
    </div>
    <join-request-modal
      :client-id="data.client.id"
      v-if="connectAccountModalActive"
      @closeModal="connectAccountModalActive = false"
      @requestSent="handleSentJoinRequest"
    ></join-request-modal>
  </div>
</template>

<script>
import LocationInaddress from "@/components/reusable/LocationInaddress";
import JoinRequestModal from "@/components/company/clients/JoinRequestModal";
import debounce from "lodash/debounce";
export default {
  components: { LocationInaddress, JoinRequestModal },
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      }
    },
    userRole: {
      type: String,
      default: ""
    },
    editMode: Boolean,
    draftLoaded: Boolean
  },
  data() {
    return {
      editing: {
        title: false,
        client: false,
        details: false
      },
      editables: {
        title: "",
        client: {
          id: "",
          name: "",
          email: "",
          telephone_number: "",
          address: ""
        },
        details: ""
      },
      editablesInited: false,
      infoCardActive: false,
      editLocation: false,
      connectAccountModalActive: false
    };
  },
  methods: {
    handleClientLocationChange(e) {
      this.editables.client.address = e;
      this.editLocation = false;
    },
    enableEdit(field) {
      if (field === "client") {
        this.editing.client = true;
      } else if (field === "title") {
        this.editing.title = true;
      } else if (field === "details") {
        this.editing.details = true;
      }
    },
    handleSentJoinRequest() {
      this.$emit("reloadProfile");
    },
    saveDetails() {
      this.$emit("saveDetails", this.editables.details);
      this.disableEdit();
    },
    debounceSaveClient: debounce(function() {
      this.saveClient();
    }, 500),
    debounceSaveDetails: debounce(function() {
      this.saveDetails();
    }, 500),
    saveClient() {
      let data = {};
      data.id = this.editables.client.id;
      data.name = this.editables.client.name;
      data.email = this.editables.client.email;
      data.address = this.editables.client.address;
      if (this.editables.client.telephone_number > 0) {
        data.telephone_number = "+372" + this.editables.client.telephone_number;
      }
      this.$emit("saveClient", data);
      this.disableEdit();
    },
    saveTitle() {
      const data = {};
      if (this.editables.title !== this.data.title) {
        data.title = this.editables.title;
      }
      if (Object.keys(data).length > 0) {
        this.$emit("changeProject", data);
      }
      this.disableEdit();
    },
    disableEdit() {
      this.editing.client = false;
      this.editing.title = false;
      this.editing.details = false;
      this.$emit("editModeDisabled");
    }
  },
  watch: {
    "editables.client": {
      deep: true,
      handler() {
        if (
          this.draftLoaded &&
          this.editablesInited &&
          this.editMode &&
          !this.clientSame
        ) {
          this.debounceSaveClient();
        }
      }
    },
    "editables.details": {
      deep: true,
      handler() {
        if (
          this.draftLoaded &&
          this.editablesInited &&
          this.editMode &&
          !this.detailsSame
        ) {
          this.debounceSaveDetails();
        }
      }
    }
  },
  computed: {
    profileImage() {
      if (this.data.client && this.data.client.profile !== null) {
        if (
          Object.keys(this.data.client.profile.image).length === 0 ||
          Object.keys(this.data.client.profile.image.image).length === 0
        ) {
          return null;
        } else {
          return (
            process.env.VUE_APP_MEDIA_URL + this.data.client.profile.image.image
          );
        }
      }
      return null;
    },
    detailsSame() {
      return this.editables.details === this.data.details;
    },
    clientSame() {
      return (
        this.editables.client.id === this.data.client.id &&
        this.editables.client.email === this.data.client.email &&
        this.editables.client.name === this.data.client.name &&
        this.editables.client.address === this.data.client.address &&
        this.editables.client.telephone_number ===
          this.data.client.telephone_number.substring(4)
      );
    }
  },
  mounted() {
    this.editablesInited = false;
    this.editables.title = this.data.title;
    this.editables.details = this.data.details;
    if (this.data.client !== null) {
      this.editables.client.id = this.data.client.id;
      this.editables.client.name = this.data.client.name;
      this.editables.client.email = this.data.client.email;
      if (this.data.client.telephone_number) {
        this.editables.client.telephone_number = this.data.client.telephone_number.substring(
          4
        );
      }
      this.editables.client.address = this.data.client.address;
    }
    this.editablesInited = true;
  }
};
</script>
