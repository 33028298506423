<template>
  <div class="flex flex-col" v-if="draftIsLoaded || clientCantSee">
    <div
      class="hidden items-start justify-between mb-4 mobile:flex"
      v-if="!clientCantSee"
    >
      <button
        class="alt-button-danger"
        @click="disableEditMode"
        v-if="editMode && canEdit"
      >
        <span class="typcn-times typcn icon"></span>
        <span class="label">Lõpeta muutmine</span>
      </button>
      <button
        class="alt-button-danger"
        @click="isConfirmDeleteModalActive = true"
        v-if="!editMode && canEdit"
        @mouseenter="deleteButtonHovered = true"
        @mouseleave="deleteButtonHovered = false"
      >
        <span class="icon"
          ><img
            src="/bc21/trash.svg"
            class="h-5"
            :class="
              deleteButtonHovered ? 'filter-to-white' : 'filter-to-danger'
            "
        /></span>
        <span class="label">Kustuta</span>
      </button>
      <button
        class="alt-button-pending"
        @click="editMode = true"
        v-if="!editMode && canEdit"
      >
        <span class="typcn-edit typcn icon"></span>
        <span class="label">Muuda</span>
      </button>
    </div>
    <div class="flex flex-col bg-white w-full" v-if="!clientCantSee">
      <div
        class="flex"
        v-if="$store.state.companyData.isClient && !noPermission"
      >
        <h2>{{ draft.title }}</h2>
      </div>
      <div
        class="flex"
        v-if="$store.state.companyData.isClient && noPermission"
      >
        <div
          class="flex bg-danger p-2 rounded-xl text-white shadow font-medium items-center"
        >
          <span class="typcn typcn-times text-2xl leading-none px-2"></span>
          <span class=""
            >Projekti haldaval teenusepakkujal puudub Gewodo Premium pakett, ehk
            teie ligipääs projektile on piiratud.</span
          >
        </div>
      </div>
      <div
        class="flex flex-row mobile:flex-col justify-between"
        v-if="!$store.state.companyData.isClient"
      >
        <div
          class="flex items-start mobile:items-center justify-center flex-col w-auto flex-auto"
        >
          <div
            class="flex mobile:flex-col mobile:items-start mobile:mb-4 break-all"
            v-if="!editMode"
          >
            <h2>{{ draft.title }}</h2>
          </div>
          <div
            class="flex mobile:flex-col items-center mobile:mb-4 mb-2 w-full pr-4"
            v-if="editMode"
          >
            <textarea
              v-if="editMode"
              v-model="tempTitle"
              v-autogrow
              rows="1"
              :disabled="saving"
              @input="debounceSaveTitle"
              class="rounded-none text-xl font-semibold border-b-2 border-green w-full bg-transparent shadow-none focus:shadow-none p-1 overflow-y-hidden"
            />
          </div>
          <div
            class="items-center flex relative duration-50 mobile:self-start mobile:w-full mb-4"
            :class="[
              statusMenuOpen ? 'shadow rounded-t-xl' : 'rounded-xl',
              canEdit ? 'hover:bg-offwhite cursor-pointer' : ''
            ]"
            @click="canEdit ? (statusMenuOpen = !statusMenuOpen) : ''"
          >
            <span
              v-if="draft.status"
              v-html="parseProjectStatus(draft.status)"
            />
            <span v-if="!draft.status" class="pl-4 pr-8 py-2"
              >Vali staatus</span
            >
            <span
              class="typcn typcn-arrow-sorted-down mr-3 ml-auto"
              v-if="canEdit"
            ></span>

            <div class="status-menu z-10" v-if="statusMenuOpen">
              <span
                class="status-menu-item"
                v-if="draft.status !== 1"
                v-html="parseProjectStatus(1)"
                @click="changeDraftStatus(1)"
              ></span>
              <span
                class="status-menu-item"
                v-if="draft.status !== 2"
                v-html="parseProjectStatus(2)"
                @click="changeDraftStatus(2)"
              ></span>
              <span
                class="status-menu-item"
                v-if="draft.status !== 3"
                v-html="parseProjectStatus(3)"
                @click="changeDraftStatus(3)"
              ></span>
              <span
                class="status-menu-item"
                v-if="draft.status !== 4"
                v-html="parseProjectStatus(4)"
                @click="changeDraftStatus(4)"
              ></span>
              <span
                class="status-menu-item"
                v-if="draft.status !== 5"
                v-html="parseProjectStatus(5)"
                @click="changeDraftStatus(5)"
              ></span>
              <span
                class="status-menu-item"
                v-if="draft.status !== 6"
                :class="draft.status === 6 ? 'rounded-b-xl' : ''"
                v-html="parseProjectStatus(6)"
                @click="changeDraftStatus(6)"
              ></span>
            </div>
          </div>
          <saving-loader
            :saving="saving"
            :saved="saved"
            class="self-start -ml-2"
          ></saving-loader>
        </div>
        <div class="flex items-start justify-end pb-1 mobile:hidden">
          <button
            class="new-button-danger"
            @click="disableEditMode"
            v-if="editMode && canEdit"
          >
            <span class="typcn-times typcn icon"></span>
            <span class="label">Lõpeta muutmine</span>
          </button>
          <button
            class="new-button-pending mr-2"
            @click="editMode = true"
            v-if="!editMode && canEdit"
          >
            <span class="typcn-edit typcn icon"></span>
            <span class="label">Muuda</span>
          </button>
          <button
            class="new-button-danger"
            @click="isConfirmDeleteModalActive = true"
            v-if="!editMode && canEdit"
          >
            <span class="icon"
              ><img src="/bc21/trash.svg" class="h-5 filter-to-white"
            /></span>
            <span class="label">Kustuta</span>
          </button>
        </div>
      </div>
      <div class="flex mb-4 mobile:flex-col" v-if="draftIsLoaded">
        <div
          class="flex w-8/12 mobile:w-full pr-2 mobile:pr-0 mobile:mb-3 flex-col"
        >
          <project-info-card
            :data="draft"
            :user-role="userRole"
            :edit-mode="editMode"
            :draft-loaded="draftIsLoaded"
            @saveClient="saveClient"
            @saveTitle="saveTitle"
            @saveDetails="saveDetails"
            @changeProject="handleEdit"
            @reloadProfile="retrieveDraft"
          ></project-info-card>
        </div>
        <div
          class="w-4/12 mobile:w-full flex pl-2 flex-col items-center justify-center mobile:px-0"
        >
          <h3 class="self-start">Projekti asukoht</h3>
          <location-card
            v-if="draftIsLoaded"
            :location="draft.location"
            class="pr-8 mobile:pr-0 mobile:mb-2"
          ></location-card>
          <div v-if="editMode" class="w-full">
            <div class="w-full ">
              <location-inaddress
                v-if="!newLocation"
                :text="'Sisestage uus projekti aadress'"
                @locationChanged="handleProjectLocationChange"
                class="mb-2"
              >
              </location-inaddress>
            </div>

            <div v-if="newLocation" class="mb-2">
              <div class="flex flex-wrap mb-2">
                <span>
                  {{ newLocation.ipikkaadress }}
                </span>
              </div>

              <div
                v-if="mapInited"
                class="flex h-full w-full shadow rounded-b-md mb-3"
                style="min-height: 350px"
              >
                <l-map
                  class="h-full w-full z-10 rounded-b-md"
                  style="min-height: 350px"
                  :options="mapOptions"
                  :center="mapOptions.center"
                  :interactive="false"
                >
                  <l-tile-layer
                    :url="tileUrl"
                    :attribution="'© OpenStreetMap contributors'"
                    :position="'bottomleft'"
                    class="h-full w-full justify-start flex"
                  ></l-tile-layer>
                </l-map>
              </div>

              <div class="flex flex-row">
                <button
                  class="new-button-danger mr-2"
                  @click="clearNewLocation"
                >
                  <span class="icon typcn-times typcn"></span>
                  <span class="label">Eemalda</span>
                </button>
                <button class="new-button-green" @click="saveLocation">
                  <span class="icon typcn typcn-tick"></span>
                  <span class="label">Salvesta</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="flex mb-4 flex-col"
        v-if="
          draftIsLoaded &&
            (['R0', 'R1', 'R2', 'R3'].includes(userRole) ||
              (draft.client &&
                draft.client.profile !== null &&
                $store.state.userData.user.id === draft.client.profile.id))
        "
      >
        <div class="w-full mb-4">
          <project-offers
            :draft="draft"
            :user-role="userRole"
            @newOffer="handleNewOffer"
            @convertToInvoice="handleInvoiceConverter"
            @offersUpdated="handleOffersUpdated"
          ></project-offers>
        </div>
        <div class="w-full">
          <project-invoices
            :draft="draft"
            :offer="offerToInvoice"
            ref="projectInvoices"
            @clear="clearOffer"
            :company="draft.company.uuid"
            :user-role="userRole"
          ></project-invoices>
        </div>
      </div>
      <div class="tab-tray" v-if="draftIsLoaded" role="tablist">
        <div
          class="tab-tray-item rounded-l-xl"
          :class="[
            subPage === 1 ? 'tab-tray-item-active' : '',
            $store.state.companyData.isClient
              ? 'w-1/3 mobile:w-full'
              : 'w-1/4 mobile:w-full'
          ]"
          role="tab"
          @click="subPage = 1"
        >
          <div class="tab-tray-icon">
            <img src="/new/ülesanded.svg" />
          </div>
          <span class="tab-tray-label">
            Ülesanded
          </span>
        </div>
        <div
          class="tab-tray-item"
          :class="[
            subPage === 3 ? 'tab-tray-item-active' : '',
            $store.state.companyData.isClient
              ? 'w-1/3 mobile:w-full'
              : 'w-1/4 mobile:w-full'
          ]"
          role="tab"
          @click="subPage = 3"
        >
          <div class="tab-tray-icon">
            <img src="/new/failid2.svg" class="h-8 w-8" />
          </div>
          <span class="tab-tray-label">
            Failid
          </span>
        </div>
        <div
          class="tab-tray-item w-1/4 mobile:w-full"
          :class="subPage === 4 ? 'tab-tray-item-active' : ''"
          @click="
            $store.state.companyData.activeCompany.subscription_permissions
              .client_portal
              ? (subPage = 4)
              : $store.dispatch('userData/openSubWarning')
          "
          role="tab"
          v-if="!$store.state.companyData.isClient"
        >
          <div class="tab-tray-icon">
            <img src="/new/ligipääsud3.svg" class="h-8 w-8" />
          </div>
          <span class="tab-tray-label">
            Töötajad
          </span>
        </div>
        <div
          class="tab-tray-item rounded-r-xl"
          :class="[
            subPage === 6 ? 'tab-tray-item-active' : '',
            $store.state.companyData.isClient
              ? 'w-1/3 mobile:w-full'
              : 'w-1/4 mobile:w-full'
          ]"
          role="tab"
          @click="
            $store.state.companyData.isClient ||
            $store.state.companyData.activeCompany.subscription_permissions
              .client_portal
              ? (subPage = 6)
              : $store.dispatch('userData/openSubWarning')
          "
        >
          <div class="tab-tray-icon">
            <img src="/new/vestlus2.svg" class="h-8 w-8" />
          </div>
          <span class="tab-tray-label">
            Vestlus
          </span>
        </div>
      </div>
      <div class="flex h-full py-2" v-if="draftIsLoaded">
        <project-tasks
          :user-role="userRole"
          :company="draft.company.uuid"
          :location="draft.location"
          :project="draft"
          v-if="subPage === 1"
          ref="projectTasks"
        ></project-tasks>
        <project-files
          v-if="subPage === 3"
          :draft="draft"
          :user-role="userRole"
          :company="draft.company.uuid"
          @updateFiles="updateProjectFiles"
        >
        </project-files>
        <project-workers
          v-if="subPage === 4"
          :draft-uuid="draft.uuid"
          :user-role="userRole"
        >
        </project-workers>
        <project-pricing
          v-if="subPage === 5"
          :draft-uuid="draft.uuid"
          :user-role="userRole"
        ></project-pricing>
        <project-chat
          ref="projectChat"
          v-if="subPage === 6"
          :draft-uuid="draft.uuid"
          :user-role="userRole"
          :company="draft.company.uuid"
        ></project-chat>
      </div>
    </div>
    <delete-confirm-modal
      text="Olete kindel, et soovite valitud projekti kustutada?"
      v-if="isConfirmDeleteModalActive"
      @closeModal="isConfirmDeleteModalActive = false"
      @confirmDelete="deleteDraft()"
    >
    </delete-confirm-modal>
    <client-cannot-access v-if="clientCantSee"></client-cannot-access>
  </div>
</template>

<script>
import RequestHandler from "@/assets/mixins/RequestHandler";
import LocationCard from "@/components/post/LocationCard";
import ProjectInfoCard from "@/components/project/ProjectInfoCard";
import ProjectOffers from "@/components/project/ProjectOffers";
import ProjectTasks from "@/components/project/ProjectTasks";
import ProjectFiles from "@/components/project/ProjectFiles";
import ProjectWorkers from "@/components/project/ProjectWorkers";
import ProjectChat from "@/components/project/comments/ProjectChat";
import ProjectPricing from "@/components/project/ProjectPricing";
import DeleteConfirmModal from "@/components/reusable/DeleteConfirmModal";
import LocationInaddress from "@/components/reusable/LocationInaddress";
import { latLng } from "leaflet";
import { LMap, LTileLayer } from "vue2-leaflet";
import ProjectInvoices from "@/components/project/invoice/ProjectInvoices";
import StatusParser from "@/assets/mixins/StatusParser";
import { TextareaAutogrowDirective } from "vue-textarea-autogrow-directive";
import debounce from "lodash/debounce";
import SavingLoader from "@/components/reusable/SavingLoader";
import ClientCannotAccess from "@/components/ClientCannotAccess";
export default {
  data() {
    return {
      draft: null,
      offers: [],
      draftIsLoaded: false,
      subPage: 1,
      userRole: null,
      editMode: false,
      isConfirmDeleteModalActive: false,
      newLocation: null,
      tileUrl: "https://{s}.tile.osm.org/{z}/{x}/{y}.png",
      noPermission: false,
      mapOptions: {
        zoom: 6,
        center: latLng(58.5953, 25.0136),
        interactive: false
      },
      mapInited: false,
      offerToInvoice: null,
      statusMenuOpen: false,
      editingTitle: false,
      tempTitle: "",
      saving: false,
      saved: false,
      clientCantSee: false,
      deleteButtonHovered: false
    };
  },
  mixins: [RequestHandler, StatusParser],
  components: {
    ClientCannotAccess,
    ProjectInvoices,
    LocationInaddress,
    DeleteConfirmModal,
    ProjectPricing,
    ProjectChat,
    ProjectWorkers,
    ProjectFiles,
    LocationCard,
    ProjectInfoCard,
    ProjectOffers,
    ProjectTasks,
    LMap,
    LTileLayer,
    SavingLoader
  },
  directives: {
    autogrow: TextareaAutogrowDirective
  },
  mounted() {
    this.loadDraftFiles();
    if (this.$route.query.task_id) {
      this.$store.dispatch("taskViewer/openTaskModal", {
        task: this.$route.query.task_id
      });
    }
    if (this.$route.query.channel_type) {
      this.subPage = 6;
      let self = this;
      setTimeout(function() {
        self.$refs.projectChat.selectedChannel = parseInt(
          self.$route.query.channel_type
        );
      }, 100);
    }
  },
  methods: {
    clearOffer() {
      this.offerToInvoice = null;
    },
    editTitle() {
      this.tempTitle = this.draft.title;
    },
    handleInvoiceConverter(e) {
      this.$refs.projectInvoices.newInvoice(e);
    },
    saveDetails(details) {
      this.saving = true;
      this.apiRequest(
        "company/draft/" + this.$route.params.projectId + "/edit/",
        "patch",
        true,
        { details: details }
      ).then(res => {
        if (res) {
          this.saving = false;
          this.saved = true;
          let self = this;
          setTimeout(function() {
            self.saved = false;
          }, 5000);
        }
      });
    },
    saveTitle() {
      this.apiRequest(
        "company/draft/" + this.$route.params.projectId + "/edit/",
        "patch",
        true,
        { title: this.tempTitle }
      ).then(res => {
        if (res) {
          this.saving = false;
          this.saved = true;
          let self = this;
          setTimeout(function() {
            self.saved = false;
          }, 5000);
        }
      });
    },
    saveLocation() {
      if (this.newLocation) {
        this.apiRequest(
          "company/draft/" + this.$route.params.projectId + "/edit/",
          "patch",
          true,
          { location: this.newLocation }
        ).then(res => {
          if (res) {
            this.saving = false;
            this.saved = true;
            let self = this;
            this.editMode = false;
            this.draftIsLoaded = false;
            this.retrieveDraft();
            setTimeout(function() {
              self.saved = false;
            }, 5000);
          }
        });
      }
    },
    clearNewLocation() {
      this.mapInited = false;
      this.newLocation = null;
    },
    handleProjectLocationChange(e) {
      this.mapInited = false;
      this.newLocation = e;
      this.mapOptions.zoom = 18;
      this.mapOptions.center = latLng(
        e.viitepunkt_b.substring(0, 2) + "." + e.viitepunkt_b.substring(3),
        e.viitepunkt_l.substring(0, 2) + "." + e.viitepunkt_l.substring(3)
      );
      this.mapInited = true;
    },
    saveClient(clientData) {
      this.saving = true;
      if (clientData.id !== "") {
        this.apiRequest(
          "company/" +
            this.$store.state.companyData.activeCompany.uuid +
            "/clients/" +
            clientData.id +
            "/edit/",
          "patch",
          true,
          clientData
        ).then(res => {
          if (res) {
            this.saving = false;
            this.saved = true;
            let self = this;
            setTimeout(function() {
              self.saved = false;
            }, 5000);
          }
        });
      } else {
        clientData.outside_post = this.$route.params.projectId;
        this.apiRequest(
          "company/" +
            this.$store.state.companyData.activeCompany.uuid +
            "/clients/add/",
          "post",
          true,
          clientData
        ).then(res => {
          if (res) {
            this.saving = false;
            this.saved = true;
            let self = this;
            setTimeout(function() {
              self.saved = false;
            }, 5000);
          }
        });
      }
    },
    deleteDraft() {
      this.apiRequest(
        "company/draft/" + this.$route.params.projectId + "/delete/",
        "delete",
        true
      ).then(res => {
        if (res.status === 200) {
          this.$router.push("/calendar/");
        }
      });
    },
    loadDraftFiles() {
      this.draftIsLoaded = false;
      this.$store.state.loading = true;
      this.retrieveDraft();
      this.$store.state.loading = false;
    },
    retrieveDraft() {
      this.$store.state.loading = true;
      let url;
      if (this.$store.state.companyData.isClient) {
        url = "notes/draft/" + this.$route.params.projectId + "/client/";
      } else {
        url = "company/drafts/" + this.$route.params.projectId + "/";
      }
      this.apiRequest(url, "get", true).then(
        res => {
          this.newLocation = null;
          this.draft = res.data;
          this.userRole = res.data.user_role;
          this.tempTitle = this.draft.title;
          document.title = `${this.draft.title} | Gewodo`;
          this.draftIsLoaded = true;
          this.$store.state.loading = false;
        },
        err => {
          let errObj = { err };
          if (errObj.err.response.status === 403) {
            this.noPermission = true;
          } else if (errObj.err.response.status === 402) {
            this.clientCantSee = true;
          }
        }
      );
    },
    retrieveClientDraftOffers() {
      this.offersLoaded = false;
      this.apiRequest(
        "notes/draft/" + this.$route.params.projectId + "/offers/client/",
        "get",
        true
      ).then(res => {
        this.offers = res.data;
        this.offersLoaded = true;
      });
    },
    updateProjectFiles(files) {
      this.documents = files;
    },
    handleNewOffer() {
      this.loadDraftFiles();
    },
    handleEdit(e) {
      this.$store.state.loading = true;
      this.apiRequest(
        "company/draft/" + this.$route.params.projectId + "/edit/",
        "patch",
        true,
        e
      ).then(res => {
        if (res) {
          this.retrieveDraft();
        }
      });
    },
    handleOffersUpdated() {
      this.$refs.projectInvoices.loadInvoices();
    },
    changeDraftStatus(status) {
      this.apiRequest(
        "company/draft/" + this.$route.params.projectId + "/edit/",
        "patch",
        true,
        { status: status.toString() }
      ).then(res => {
        if (res.status === 200) {
          this.statusMenuOpen = false;
          this.draftIsLoaded = false;
          this.draft = null;
          this.retrieveDraft();
        }
      });
    },
    handleTaskEdit() {
      this.$refs.projectTasks.retrieveTasks();
    },
    disableEditMode() {
      this.editMode = false;
      this.retrieveDraft();
    },
    debounceSaveTitle: debounce(function() {
      this.saveTitle();
    }, 500)
  },
  computed: {
    canEdit() {
      return ["R0", "R1", "R2"].includes(this.userRole);
    }
  },
  watch: {
    tempTitle() {
      if (this.editMode && this.draftIsLoaded) {
        this.debounceSaveTitle();
      }
    }
  }
};
</script>
