<template>
  <div class="modal">
    <div class="modal-body modal-small">
      <div class="modal-content flex flex-col">
        <h3 class="self-center mb-2">Saada kliendile liitumiskutse</h3>
        <h4>Kliendi meiliaadress</h4>
        <input type="text" v-model="emailInput" class="mb-2" />
      </div>
      <div class="modal-bottom-bar">
        <button class="new-button-green mr-3" @click="sendJoinRequest">
          <span class="icon">
            <img src="/bc21/mail.svg" class="filter-to-white" />
          </span>
          <span class="label">Saada</span>
        </button>
        <button class="new-button-danger" @click="closeModal">
          <span class="typcn typcn-times icon"></span>
          <span class="label">Tühista</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import RequestHandler from "@/assets/mixins/RequestHandler";
export default {
  props: {
    clientId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      emailInput: ""
    };
  },
  mixins: [RequestHandler],
  methods: {
    sendJoinRequest() {
      this.apiRequest(
        "company/" +
          this.$store.state.companyData.activeCompany.uuid +
          "/clients/" +
          this.clientId +
          "/request/join/",
        "post",
        true,
        { email: this.emailInputLowercased }
      ).then(res => {
        if (res) {
          this.$emit("requestSent");
          this.closeModal();
        }
      });
    },
    closeModal() {
      this.$emit("closeModal");
    }
  },
  computed: {
    emailInputLowercased() {
      return this.emailInput.toLowerCase();
    }
  }
};
</script>
