<template>
  <div class="flex flex-col bg-white w-full" v-if="invoicesLoaded">
    <div class="flex items-center">
      <h2 class="mr-3">Arved</h2>
      <button
        class="alt-button-green"
        v-if="['R0', 'R1', 'R2'].includes(userRole)"
        @click="
          $store.state.companyData.activeCompany.subscription_permissions
            .invoices
            ? newInvoice()
            : $store.dispatch('userData/openSubWarning')
        "
      >
        <span class="typcn typcn-plus icon"></span>
        <span class="label">Lisa</span>
      </button>
    </div>
    <div class="flex flex-col py-3" v-if="invoicesLoaded && !offer">
      <div v-if="invoices.length === 0">
        Arveid pole!
      </div>
      <transition-group name="list">
        <invoice-card
          v-for="invoice in invoicesExpanded ? invoices : invoices.slice(0, 3)"
          :key="invoice.id"
          :invoice="invoice"
          :draft="draft"
          :company="company"
          :user-role="userRole"
        >
        </invoice-card>
      </transition-group>
    </div>
    <div class="flex flex-col items-start" v-if="invoices.length > 3">
      <button
        :class="invoicesExpanded ? 'new-button-danger' : 'new-button-green'"
        @click="invoicesExpanded = !invoicesExpanded"
      >
        <span
          class="typcn typcn-chevron-right icon transform duration-100"
          :class="invoicesExpanded ? '-rotate-90' : 'rotate-90'"
        ></span>
        <span class="label"
          >Näita {{ invoicesExpanded ? "vähem" : invoicesToLoad }}</span
        >
      </button>
    </div>
  </div>
</template>

<script>
import InvoiceCard from "@/components/project/invoice/InvoiceCard";
import RequestHandler from "@/assets/mixins/RequestHandler";
import EventBus from "@/assets/mixins/EventBus";
export default {
  name: "ProjectInvoices",
  components: { InvoiceCard },
  props: {
    draft: {
      type: Object,
      default: () => {
        return {};
      }
    },
    offer: {
      type: Object,
      default: () => {
        return {};
      }
    },
    company: {
      type: String,
      default: ""
    },
    userRole: {
      type: String,
      default: "R6"
    }
  },
  data() {
    return {
      showAddForm: false,
      invoices: [],
      invoicesLoaded: false,
      invoicesExpanded: false,
      createdInvoice: null
    };
  },
  mixins: [RequestHandler],
  mounted() {
    this.loadInvoices();
    EventBus.$on("updateInvoices", () => {
      this.loadInvoices();
    });
  },
  beforeDestroy() {
    EventBus.$off("updateInvoices");
  },
  methods: {
    clearOfferToInvoice() {
      this.offer = null;
      this.$emit("clear");
    },
    handleInvoiceSave(invoiceId) {
      this.createdInvoice = invoiceId;
      this.loadInvoices();
    },
    loadInvoices() {
      this.invoicesLoaded = false;
      if (!this.$store.state.companyData.isClient) {
        this.apiRequest(
          "notes/draft/" + this.$route.params.projectId + "/invoices/",
          "get",
          true
        ).then(res => {
          this.invoices = res.data;
          if (this.$store.state.invoiceViewer.invoiceModal.show) {
            this.$store.state.invoiceViewer.invoiceModal.data.invoice = this.invoices.find(
              x =>
                x.id ===
                this.$store.state.invoiceViewer.invoiceModal.data.invoice.id
            );
          }
          if (this.createdInvoice) {
            this.$store.dispatch("invoiceViewer/openInvoiceModal", {
              invoice: this.invoices.find(x => x.id === this.createdInvoice),
              draft: this.draft
            });
          }

          this.invoicesLoaded = true;
        });
      } else {
        this.apiRequest(
          "notes/draft/" + this.$route.params.projectId + "/invoices/client/",
          "get",
          true
        ).then(res => {
          this.invoices = res.data;
          this.invoicesLoaded = true;
        });
      }
    },
    newInvoice() {
      this.$store.dispatch("invoiceViewer/openAddInvoiceModal", {
        offer: null,
        draft: this.draft
      });
    }
  },
  computed: {
    invoicesToLoad() {
      return "kõiki (" + parseInt(this.invoices.length - 3) + ")";
    }
  }
};
</script>
