var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col mobile:w-full w-2/12 bg-white rounded-l-gwdhalf text-darkgrey mobile:rounded-none mobile:rounded-t-gwdhalf mobile:rounded-b-xl"},[_vm._m(0),_c('div',{staticClass:"flex flex-col mobile:flex-row"},[(['R0', 'R1', 'R2', 'R3'].includes(_vm.userRole))?_c('div',{staticClass:"w-full p-3 cursor-pointer duration-100 rounded-tl-gwdhalf mobile:w-1/3 mobile:justify-center mobile:text-center mobile:items-center flex mobile:border-b-2",class:_vm.selectedChannel === 1
          ? 'border-r-4 mobile:border-r-0 border-green hover:bg-white text-green'
          : 'hover:bg-darkgreen hover:text-white border-offwhite',on:{"click":function($event){return _vm.selectChannel(1)}}},[_c('span',{staticClass:"font-medium text-lg"},[_vm._v("Projekti juhid")])]):_vm._e(),(['R0', 'R1', 'R2', 'R3', 'R4', 'R5'].includes(_vm.userRole))?_c('div',{staticClass:"w-full p-3 cursor-pointer duration-100 mobile:justify-center mobile:text-center mobile:items-center flex mobile:border-b-2",class:[
        _vm.selectedChannel === 2
          ? 'border-r-4 mobile:border-r-0 border-green hover:bg-white text-green'
          : 'hover:bg-darkgreen hover:text-white border-offwhite',
        ['R4', 'R5'].includes(_vm.userRole) ? 'mobile:w-full' : 'mobile:w-1/3'
      ],on:{"click":function($event){return _vm.selectChannel(2)}}},[_c('span',{staticClass:"font-medium text-lg"},[_vm._v("Meeskond")])]):_vm._e(),(
        ['R0', 'R1', 'R2'].includes(_vm.userRole) ||
          _vm.$store.state.companyData.isClient
      )?_c('div',{staticClass:"p-3 cursor-pointer duration-100 mobile:justify-center mobile:text-center flex mobile:items-center mobile:border-b-2",class:[
        _vm.selectedChannel === 3
          ? 'border-r-4 mobile:border-r-0 border-green hover:bg-white text-green'
          : 'hover:bg-darkgreen hover:text-white border-offwhite',
        _vm.$store.state.companyData.isClient
          ? 'rounded-tl-gwdhalf w-full'
          : ' mobile:w-1/3 w-full'
      ],on:{"click":function($event){return _vm.selectChannel(3)}}},[_c('span',{staticClass:"font-medium text-lg"},[_vm._v("Klient")])]):_vm._e()])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"hidden mobile:flex self-center"},[_c('h4',[_vm._v("Valitud kanal")])])}]

export { render, staticRenderFns }