<template>
  <div class="flex flex-col py-5" v-if="activeFileLoaded">
    <div class="flex items-center justify-center">
      <img :src="parseFileTypeIcon()" class="h-16 w-16" />
    </div>
    <div class="flex justify-center">
      <span
        class="text-lg break-all"
        v-if="!editing && activeFileData.description !== ''"
        >{{ activeFileData.description }}</span
      >
      <span
        class="text-lg break-all"
        v-if="!editing && activeFileData.description === ''"
        >{{ activeFileData.file_name }}</span
      >
      <input v-if="editing" v-model="editables.description" />
    </div>
    <span>{{ activeFileData.size }}</span>
    <span>{{ activeFileData.uploaded_by }}</span>
    <span>{{
      moment(activeFileData.created_at).format("DD.MM.YYYY HH:mm")
    }}</span>
    <span class="font-semibold">Nähtavus</span>
    <div class="flex bg-offwhite rounded-gwdfull px-3 py-1 mb-1 shadow">
      <div class="w-9/12 flex justify-start"><span>Haldajad</span></div>
      <div class="w-3/12 flex justify-center">
        <span
          class="typcn typcn-tick text-green"
          v-if="activeFileData.visible_to_managers && !editing"
        ></span>
        <span
          class="typcn typcn-times text-danger"
          v-if="!activeFileData.visible_to_managers && !editing"
        ></span>
        <input
          type="checkbox"
          v-if="editing"
          class="shadow-none focus:shadow-none"
          v-model="editables.visible_to_managers"
        />
      </div>
    </div>
    <div class="flex bg-offwhite rounded-gwdfull px-3 py-1 mb-1 shadow">
      <div class="w-9/12 flex justify-start"><span>Töötajad</span></div>
      <div class="w-3/12 justify-center flex">
        <span
          class="typcn typcn-tick text-green"
          v-if="activeFileData.visible_to_workers && !editing"
        ></span>
        <span
          class="typcn typcn-times text-danger"
          v-if="!activeFileData.visible_to_workers && !editing"
        ></span>
        <input
          type="checkbox"
          class="shadow-none focus:shadow-none"
          v-if="editing"
          v-model="editables.visible_to_workers"
        />
      </div>
    </div>
    <div class="flex bg-offwhite rounded-gwdfull px-3 py-1 shadow mb-1">
      <div class="w-9/12 flex justify-start"><span>Klient</span></div>
      <div class="w-3/12 justify-center flex">
        <span
          class="typcn typcn-tick text-green"
          v-if="activeFileData.visible_to_client && !editing"
        ></span>
        <span
          class="typcn typcn-times text-danger"
          v-if="!activeFileData.visible_to_client && !editing"
        ></span>
        <input
          type="checkbox"
          class="shadow-none focus:shadow-none"
          v-if="editing"
          v-model="editables.visible_to_client"
        />
      </div>
    </div>
    <button
      @click="editing = true"
      class="mb-2 mt-2 new-button-pending"
      v-if="!editing && editPermissions"
    >
      <span class="typcn typcn-edit icon"></span>
      <span class="label">Muuda</span>
    </button>
    <button @click="saveFile" v-if="editing" class="new-button-green">
      Salvesta
    </button>
    <button @click="editing = false" v-if="editing" class="new-button-danger">
      Tühista
    </button>
    <button
      class="mb-2 new-button-danger"
      @click="deleteModalActive = true"
      v-if="editPermissions && showDelete"
    >
      <span class="icon"
        ><img src="/bc21/trash.svg" class="h-5 filter-to-white"
      /></span>
      <span class="label">Kustuta</span>
    </button>
    <a
      v-if="!editing"
      download
      @click.prevent="
        $store.state.companyData.isClient
          ? downloadFileAsClient()
          : downloadFile()
      "
      target="_blank"
      class="w-full"
    >
      <button class="w-full new-button-green">
        <span class="typcn typcn-download icon"></span
        ><span class="label">Lae alla</span>
      </button>
    </a>
    <delete-confirm-modal
      v-if="deleteModalActive"
      @confirm="deleteFile"
      @closeModal="deleteModalActive = false"
      text="Olete kindel, et soovite valitud faili kustutada?"
    >
    </delete-confirm-modal>
  </div>
</template>

<script>
import moment from "moment";
import DeleteConfirmModal from "@/components/reusable/ConfirmModal";
import FileTypeParser from "@/assets/mixins/FileTypeParser";
import RequestHandler from "@/assets/mixins/RequestHandler";

export default {
  props: {
    activeFile: {
      type: Object,
      default: () => {
        return {};
      }
    },
    showDelete: Boolean,
    type: {
      type: String,
      default: () => {
        return "file";
      }
    },
    draft: {
      type: String,
      default: null
    },
    company: {
      type: String,
      default: null
    }
  },
  components: {
    DeleteConfirmModal
  },
  mixins: [FileTypeParser, RequestHandler],
  data() {
    return {
      moment: moment,
      editing: false,
      editPermissions: !this.$store.state.companyData.isClient
        ? ["R0", "R1", "R2"].includes(
            this.$store.state.companyData.activeCompany.worker_permission
          )
        : false,
      deleteModalActive: false,
      editables: {
        id: this.activeFile.id,
        visible_to_workers: false,
        visible_to_managers: false,
        visible_to_client: false,
        description: ""
      },
      activeFileData: null,
      activeFileLoaded: false
    };
  },
  methods: {
    parseFileTypeIcon() {
      return this.parseFileType(this.activeFile);
    },
    deleteFile() {
      let url;
      if (this.type === "workerFile") {
        url =
          "company/" +
          this.$store.state.companyData.activeCompany.uuid +
          "/worker/files/" +
          this.activeFile.id +
          "/delete/";
      } else {
        url =
          "company/" +
          this.$store.state.companyData.activeCompany.uuid +
          "/files/delete/";
      }
      this.apiRequest(url, "delete", true, {
        document_id_list: [this.activeFile.id]
      }).then(res => {
        if (res.status === 200) {
          this.activeFileLoaded = false;
          this.activeFileData = null;
          this.$emit("fileDeleted");
        }
      });
    },
    saveFile() {
      this.apiRequest(
        "company/" +
          this.$store.state.companyData.activeCompany.uuid +
          "/files/edit/",
        "patch",
        true,
        this.editables
      ).then(res => {
        if (res) {
          this.$emit("fileDeleted");
        }
      });
    },

    downloadFile() {
      if (this.activeFile) {
        this.apiRequest(
          "files/private/" +
            this.$store.state.companyData.activeCompany.uuid +
            "/" +
            this.activeFile.id +
            "/?type=" +
            this.type,
          "get",
          true
        ).then(res => {
          const url = res.data.file_url;
          const link = document.createElement("a");
          link.href = url;
          link.target = "_blank";
          link.setAttribute("download", "arve.pdf");
          document.body.appendChild(link);
          link.click();
        });
      }
    },
    downloadFileAsClient() {
      if (this.activeFile) {
        this.apiRequest(
          "files/private/" +
            this.company +
            "/" +
            this.activeFile.id +
            "/?type=" +
            this.type +
            "&project_uuid=" +
            this.draft,
          "get",
          true
        ).then(res => {
          const url = res.data.file_url;
          const link = document.createElement("a");
          link.href = url;
          link.target = "_blank";
          link.setAttribute("download", "arve.pdf");
          document.body.appendChild(link);
          link.click();
        });
      }
    },
    retrieveFileDataAsClient() {
      this.apiRequest(
        "notes/draft/" + this.draft + "/file/" + this.activeFile.id + "/",
        "get",
        true
      ).then(res => {
        this.activeFileData = res.data;
        this.activeFileLoaded = true;
      });
    },
    retrieveFileData() {
      let url;
      if (this.type === "workerFile") {
        url =
          "company/" +
          this.$store.state.companyData.activeCompany.uuid +
          "/worker/" +
          this.$route.params.id +
          "/files/" +
          this.activeFile.id +
          "/";
      } else {
        url =
          "company/" +
          this.$store.state.companyData.activeCompany.uuid +
          "/files/" +
          this.activeFile.id +
          "/";
      }
      this.apiRequest(url, "get", true).then(res => {
        this.activeFileData = res.data;
        this.editables.description = res.data.description;
        this.editables.visible_to_workers = res.data.visible_to_workers;
        this.editables.visible_to_managers = res.data.visible_to_managers;
        this.editables.visible_to_client = res.data.visible_to_client;
        this.activeFileLoaded = true;
      });
    }
  },
  mounted() {
    if (this.$store.state.companyData.isClient) {
      this.retrieveFileDataAsClient();
    } else {
      this.retrieveFileData();
    }
  },
  watch: {
    activeFile: {
      deep: true,
      handler() {
        if (this.$store.state.companyData.isClient) {
          this.retrieveFileDataAsClient();
        } else {
          this.retrieveFileData();
        }
      }
    }
  }
};
</script>
