<template>
  <div class="flex-col flex w-full">
    <div
      class="self-center w-full flex flex-col justify-center pt-2 pb-3"
      v-if="showDateHeader"
    >
      <span class="px-2">{{
        moment(comment.created_at).format("DD.MM.YYYY")
      }}</span>
      <span class="h-1 w-full bg-offwhite rounded-xl"></span>
    </div>
    <div
      class="flex w-full mb-2"
      :class="[
        comment.author.id === $store.state.userData.user.id
          ? 'justify-end'
          : 'justify-start'
      ]"
    >
      <div class="flex flex-col max-w-full mobile:w-full">
        <div
          v-if="
            comment.author.id === $store.state.userData.user.id && showHeader
          "
          class="flex flex-row w-full items-center justify-end mb-1"
        >
          <div class="flex items-center bg-white p-1">
            <span class="text-left px-3 font-semibold"
              >{{ comment.author.display_username }}
            </span>
            <span class="flex text-center disabled">{{ parseDate }}</span>
          </div>
        </div>
        <div
          v-if="
            comment.author.id !== $store.state.userData.user.id && showHeader
          "
          class="flex flex-row w-full items-center justify-start"
        >
          <span class="flex text-center disabled">{{ parseDate }}</span>
          <a
            :href="'/profile/' + comment.author.id"
            target="_blank"
            class="text-left px-3 font-semibold"
            >{{ comment.author.display_username }}
            <span
              class="flex text-center hover:text-green cursor-pointer"
              @click="$router.push('/profile/' + author.id)"
            ></span>
          </a>
        </div>
        <div
          class="flex flex-col rounded-xl py-2 px-3"
          :class="[
            comment.author.id === $store.state.userData.user.id
              ? 'bg-offwhite'
              : 'bg-offwhite',
            comment.documents.length > 0 || commentFiles.length > 0
              ? 'mb-1'
              : ''
          ]"
          v-if="comment.content.length > 0"
        >
          <span
            class="break-words"
            :class="
              comment.author.id === $store.state.userData.user.id ? '' : ''
            "
            v-if="comment.status === 1"
            >{{ comment.content }}</span
          >
          <span v-if="comment.status === 2">Kommentaar kustutatud</span>
        </div>
        <div v-if="comment.documents.length > 0">
          <div class="flex flex-wrap">
            <project-image
              v-for="image in comment.documents.filter(x =>
                ['image/png', 'image/jpeg', 'image/svg+xml'].includes(
                  x.file_type
                )
              )"
              :image="image"
              :key="image.id"
              :type="'comment_image'"
              :company-uuid="company"
              @active="activateImage"
            ></project-image>
          </div>
          <div v-if="commentFiles.length > 0">
            <div class="flex flex-col">
              <comment-file
                v-for="file in commentFiles"
                :key="file.id"
                :file="file"
                :company="company"
              ></comment-file>
            </div>
          </div>
        </div>
      </div>
      <image-modal
        v-if="activeImage"
        :image-url="activeImage"
        :img-array="
          comment.documents.filter(x =>
            ['image/png', 'image/jpeg', 'image/svg+xml'].includes(x.file_type)
          )
        "
      ></image-modal>
      <div
        v-if="activeImage"
        class="modal overflow-x-hidden"
        @click="activeImage = null"
      >
        <div class="image-modal overflow-x-hidden cursor-pointer">
          <img :src="activeImage" class="modal-image border-2 border-green" />
          <div
            @click="activeImage = null"
            class="close-button leading-none bg-danger flex justify-center items-center"
          >
            <span class="text-4xl typcn typcn-times leading-none"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import CommentFile from "@/components/project/comments/CommentFile";
import ProjectImage from "@/components/project/ProjectImage";
import ImageModal from "@/components/reusable/ImageModal";

export default {
  name: "ProjectComment",
  components: { ProjectImage, CommentFile, ImageModal },
  props: {
    comment: {
      type: Object,
      default: () => {
        return {};
      }
    },
    company: {
      type: String,
      default: ""
    },
    showHeader: Boolean,
    showDateHeader: Boolean
  },
  data() {
    return {
      replying: false,
      replyContent: "",
      confirmModalIsActive: false,
      showDocuments: false,
      commentText: "",
      commentFiles: [],
      activeImage: null,
      moment: moment
    };
  },
  methods: {
    activateImage(e) {
      if (this.activeImage === null) {
        this.activeImage = e;
      } else if (this.activeImage === e) {
        this.activeImage = null;
      } else {
        this.activeImage = e;
      }
    }
  },
  computed: {
    profileImage() {
      if (Object.keys(this.comment.author.image).length > 0) {
        return process.env.VUE_APP_MEDIA_URL + this.comment.author.image.image;
      } else {
        return "/favicon.ico";
      }
    },
    parseDate() {
      return moment(this.comment.created_at).format("HH:mm");
    },
    parseTime() {
      return moment(this.comment.created_at).format("DD.MM.YYYY HH:mm:ss");
    },
    replyButtonText() {
      if (this.replying) {
        return "Sulge";
      } else {
        return "Vasta";
      }
    }
  },
  mounted() {
    this.commentFiles = this.comment.documents.filter(
      x => !["image/png", "image/jpeg", "image/svg+xml"].includes(x.file_type)
    );
    if (this.commentFiles.length === 0) {
      this.commentText = "";
    } else if (this.commentFiles.length === 1) {
      this.commentText = "fail";
    } else if (this.commentFiles.length > 1) {
      this.commentText = "faili";
    }
  }
};
</script>
