<template>
  <div
    class="flex bg-white rounded-gwdhalf items-center flex-col cursor-pointer duration-100"
    @click="activateInvoice"
  >
    <div
      class="flex w-full justify-center mobile:justify-between items-center top-row py-3 hover:bg-offwhite hover:bg-opacity-50 px-3 mobile:flex-wrap mobile:px-0"
    >
      <div class="w-3/12 flex justify-start mobile:w-6/12 items-center">
        <h4>Arve #{{ invoice.invoice_index }}</h4>
        <div
          class="hidden mobile:flex"
          v-html="parseDraftInvoiceStatusNoText(invoice.status)"
        ></div>
      </div>
      <div class="w-3/12 mobile:justify-end flex items-center mobile:w-6/12">
        <span
          class="bg-green text-white rounded-gwdfull shadow w-1 h-8 justify-center flex text-xl mr-2"
        ></span>
        <h4 class="font-medium">{{ invoice.price }}€</h4>
      </div>
      <div class="w-3/12 flex justify-start mobile:w-4/12 mobile:hidden">
        {{ moment(invoice.created_at).format("DD.MM.YYYY") }}
      </div>
      <div
        class="flex relative w-3/12 mobile:justify-end mobile:w-8/12 mobile:hidden"
      >
        <div v-html="parseDraftInvoiceStatus(invoice.status)"></div>
      </div>
    </div>
    <div class="bg-offwhite rounded-full w-full h-1"></div>
  </div>
</template>

<script>
import StatusParser from "@/assets/mixins/StatusParser";
import RequestHandler from "@/assets/mixins/RequestHandler";
import cloneDeep from "lodash/cloneDeep";
import moment from "moment";
export default {
  props: {
    invoice: {
      type: Object,
      default: () => {
        return {};
      }
    },
    draft: {
      type: Object,
      default: () => {
        return {};
      }
    },
    activeinvoiceId: {
      type: Number,
      default: null
    },
    company: {
      type: String,
      default: ""
    },
    userRole: {
      type: String,
      default: "R6"
    }
  },
  mixins: [StatusParser, RequestHandler],
  mounted() {
    this.tempData = cloneDeep(this.invoice.items);
    this.details = this.invoice.details;
  },
  data() {
    return {
      showServices: false,
      tempData: null,
      newServices: [],
      showEmailForm: false,
      editMode: false,
      details: "",
      moment: moment,
      confirmRemoveModalActive: false,
      statusMenuOpen: false
    };
  },
  methods: {
    changeInvoiceStatus(status) {
      this.apiRequest(
        "notes/draft/" +
          this.$route.params.projectId +
          "/invoice/" +
          this.invoice.id +
          "/edit/",
        "patch",
        true,
        { status: status }
      ).then(res => {
        if (res) {
          this.$emit("invoicesUpdated");
        }
      });
    },
    downloadPDF() {
      this.apiRequest(
        "files/private/" +
          (this.company !== null &&
          this.company !== "" &&
          this.company !== undefined
            ? this.company
            : this.$store.state.companyData.activeCompany.uuid) +
          "/" +
          this.invoice.pdf[this.invoice.pdf.length - 1].id +
          "/?type=file" +
          (this.$store.state.companyData.isClient
            ? "&project_uuid=" + this.$route.params.projectId
            : ""),
        "get",
        true
      ).then(res => {
        const url = res.data.file_url;
        const link = document.createElement("a");
        link.href = url;
        link.target = "_blank";
        link.setAttribute("download", "arve.pdf");
        document.body.appendChild(link);
        link.click();
      });
    },
    genId(item) {
      const gennedId = Math.round(Math.random() * 100000);
      if (item) {
        this.tempData.find(x => x.id === item.id).index = gennedId;
      }
      return gennedId;
    },
    convertToInvoice() {
      this.$emit("convertToInvoice", this.invoice);
    },
    handleServiceUpdateNew(e) {
      let itemToChange = this.newServices.findIndex(
        item => item.index === e.index
      );
      this.newServices[itemToChange] = e;
    },
    removeService(e) {
      this.newServices.splice(
        this.newServices.findIndex(item => item.index === e),
        1
      );
    },
    activateInvoice() {
      this.$store.dispatch("invoiceViewer/openInvoiceModal", {
        invoice: this.invoice,
        draft: this.draft
      });
    },
    handleServiceUpdate(e) {
      let itemToChange = this.tempData.findIndex(item => item.id === e.id);
      this.tempData[itemToChange] = e;
    },
    handleRemoveService(e) {
      this.tempData.splice(
        this.tempData.findIndex(x => x.id === e),
        1
      );
    },
    deleteInvoice() {
      this.$store.state.loading = true;
      this.apiRequest(
        "notes/company/" +
          this.$store.state.companyData.activeCompany.uuid +
          "/" +
          this.$route.params.projectId +
          "/invoice/" +
          this.invoice.id +
          "/delete/",
        "delete",
        true
      ).then(res => {
        if (res) {
          this.$store.state.loading = false;
          this.$emit("invoicesUpdated");
        }
      });
    },
    addService() {
      let data = {
        index: null,
        name: "",
        price: 0,
        amount: 0,
        unit: 1,
        vat: 20
      };
      this.newServices.push(data);
    },
    sendEmail(data) {
      data["details"] = this.details;
      this.apiRequest(
        "notes/draft/" +
          this.$route.params.projectId +
          "/invoice/" +
          this.invoice.id +
          "/edit/",
        "patch",
        true,
        data
      ).then(res => {
        if (res) {
          this.$store.state.loading = true;
          this.apiRequest(
            "notes/draft/" +
              this.$route.params.projectId +
              "/invoices/" +
              this.invoice.id +
              "/send/",
            "post",
            true
          ).then(res2 => {
            if (res2) {
              this.$store.state.loading = false;
              this.$emit("invoicesUpdated");
            }
          });
        }
      });
    },
    updateinvoiceData(data) {
      this.apiRequest(
        "notes/draft/" +
          this.$route.params.projectId +
          "/invoice/" +
          this.invoice.id +
          "/edit/",
        "patch",
        true,
        data
      ).then(res => {
        if (res) {
          this.$emit("invoicesUpdated");
        }
      });
    },
    saveNewServices() {
      let data = [];
      for (let i = 0; i < this.newServices.length; i++) {
        let obj = {};
        obj.name = this.newServices[i].name;
        obj.amount = this.newServices[i].amount;
        obj.unit = this.newServices[i].unit;
        obj.price = this.newServices[i].price;
        obj.vat = this.newServices[i].vat;
        obj.invoice = this.invoice.id;

        data.push(obj);
      }

      this.apiRequest(
        "notes/draft/" +
          this.$route.params.projectId +
          "/invoice/" +
          this.invoice.id +
          "/items/add/",
        "post",
        true,
        data
      ).then(res => {
        res;
      });
    },
    saveinvoice() {
      let data = {};

      data.items = this.tempData;
      this.apiRequest(
        "notes/draft/" +
          this.$route.params.projectId +
          "/invoice/" +
          this.invoice.id +
          "/items/edit/",
        "patch",
        true,
        this.tempData
      ).then(res => {
        if (this.newServices.length > 0) {
          this.saveNewServices();
        }
        if (res.status === 200) {
          let sum = 0;
          for (let i = 0; i < this.tempData.length; i++) {
            sum +=
              parseFloat(this.tempData[i].price) *
              parseFloat(this.tempData[i].amount);
            if (this.tempData[i].vat === 20) {
              sum +=
                parseFloat(this.tempData[i].price) *
                parseFloat(this.tempData[i].amount) *
                0.2;
            }
          }

          for (let i = 0; i < this.newServices.length; i++) {
            sum +=
              parseFloat(this.newServices[i].price) *
              parseFloat(this.newServices[i].amount);

            if (this.newServices[i].vat === 20) {
              sum +=
                parseFloat(this.newServices[i].price) *
                parseFloat(this.newServices[i].amount) *
                0.2;
            }
          }
          sum = sum.toFixed(2);
          let data = {
            post: this.tempData.id,
            status: this.invoice.status,
            details: this.details,
            price: sum
          };

          this.apiRequest(
            "notes/draft/" +
              this.$route.params.projectId +
              "/invoice/" +
              this.invoice.id +
              "/edit/",
            "patch",
            true,
            data
          ).then(res => {
            if (res.status === 201) {
              this.$emit("invoicesUpdated");
            }
          });
        }
      });
    }
  },

  computed: {
    mediaUrl() {
      return process.env.VUE_APP_MEDIA_URL;
    }
  }
};
</script>
