<template>
  <div class="flex flex-col bg-white rounded-gwdhalf py-3 w-full">
    <div class="flex justify-between">
      <button
        v-if="['R0', 'R1', 'R2', 'R3'].includes(userRole)"
        @click="openAddTaskView"
        class="new-button-green"
      >
        <span class="typcn typcn-plus icon"></span>
        <span class="label">Lisa ülesanne</span>
      </button>
    </div>
    <div class="flex">
      <weekly-calendar-project
        ref="weeklyCalendar"
        @addTask="openAddTaskView"
        :company="company"
      ></weekly-calendar-project>
    </div>
  </div>
</template>

<script>
import RequestHandler from "@/assets/mixins/RequestHandler";
import StatusParser from "@/assets/mixins/StatusParser";
import WeeklyCalendarProject from "@/components/calendar/WeeklyCalendarProject";
import moment from "moment";
import EventBus from "@/assets/mixins/EventBus";
export default {
  props: {
    userRole: { type: String, default: "R6" },
    company: {
      type: String,
      default: ""
    },
    location: {
      type: Object,
      default: null
    },
    project: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      showAddForm: false,
      tasks: null,
      tasksInited: false,
      addFormDate: null,
      moment: moment
    };
  },
  mixins: [RequestHandler, StatusParser],
  components: {
    WeeklyCalendarProject
  },
  mounted() {
    this.retrieveTasks();
    EventBus.$on("updateTasks", () => {
      this.retrieveTasks();
    });
  },
  beforeDestroy() {
    EventBus.$off("updateTasks");
  },
  methods: {
    retrieveTasks() {
      this.tasksInited = false;
      let url;
      if (this.$store.state.companyData.isClient) {
        url =
          "calendar/" +
          this.company +
          "/" +
          this.$route.params.projectId +
          "/tasks/client/?period=month&start_time=" +
          moment(this.startOfWeek).format("YYYY-MM");
      } else {
        url =
          "calendar/" +
          this.$store.state.companyData.activeCompany.uuid +
          "/" +
          this.$route.params.projectId +
          "/tasks/?period=month&start_time=" +
          moment(this.startOfWeek).format("YYYY-MM");
      }
      this.apiRequest(url, "get", true).then(res => {
        this.tasks = res.data;
        this.$refs.weeklyCalendar.initWeek(0);
        this.tasksInited = true;
      });
    },
    taskStatus(status) {
      return this.parseTaskStatus(status);
    },
    viewTask(id) {
      this.$store.dispatch("taskViewer/openTaskModal", {
        task: id,
        draft: this.project
      });
    },
    handleTaskSaved() {
      this.showAddForm = false;
      this.$refs.weeklyCalendar.initWeek(0);
    },
    openAddTaskView(e) {
      if (e && typeof e === Object)
        this.$store.dispatch("taskViewer/openAddTaskModal", {
          presetData: e,
          hasPresetData: true,
          location: this.location,
          relatedProject: this.project
        });
      else
        this.$store.dispatch("taskViewer/openAddTaskModal", {
          presetData: null,
          hasPresetData: false,
          location: this.location,
          relatedProject: this.project
        });
    }
  }
};
</script>
