<template>
  <div
    class="flex flex-col py-3 px-1 w-full mobile:items-center bg-white rounded-gwdhalf mb-5"
  >
    <div v-if="files.length === 0 && filesLoaded" class="pt-2 pb-4">
      <span>Projektil puuduvad dokumendid.</span>
    </div>
    <div class="flex flex-row w-full">
      <div
        v-if="files.length > 0 && filesLoaded"
        class="flex flex-row flex-wrap -mx-1 mb-2"
        :class="[showSidebar ? 'w-10/12 pr-1 mobile:hidden' : 'w-full']"
      >
        <project-file
          v-for="file in files"
          :key="file.id"
          :file="file"
          :file-types="fileTypes"
          :user-role="userRole"
          :company="company"
          :draft-uuid="draft.uuid"
          @reloadFiles="retrieveFiles"
          @expandFile="openSidebar"
          @closeFile="closeSidebar"
          :current-expanded="expandedFile ? expandedFile.id : null"
        >
        </project-file>
      </div>
      <div
        class="flex w-2/12 pl-1 mobile:w-full mobile:pl-0 mobile:mb-3"
        v-if="showSidebar"
      >
        <file-sidebar
          :active-file="expandedFile"
          :storage-data="{}"
          :user-role="userRole"
          :show-delete="true"
          :draft="draft.uuid"
          :company="company"
          @fileDeleted="fileDeleteHandler"
          @closeSidebar="showSidebar = false"
          class="rounded-md"
        ></file-sidebar>
      </div>
    </div>

    <div v-if="['R0', 'R1', 'R2', 'R3', 'R5'].includes(userRole)">
      <button
        class=""
        @click="uploadWidgetActive = !uploadWidgetActive"
        v-if="!uploadWidgetActive"
        :class="uploadWidgetActive ? 'alt-button-danger' : 'alt-button-green'"
      >
        <span
          class="icon typcn typcn-plus transform duration-150"
          :class="uploadWidgetActive ? 'rotate-45' : ''"
        ></span>
        <span class="label" v-if="!uploadWidgetActive">Lisa uus</span>
      </button>
    </div>
    <display-file-modal
      v-if="$store.state.fileViewer.displayFileModal"
      :file="$store.state.fileViewer.fileToDisplay"
      :company="$store.state.fileViewer.company"
      :draft="$store.state.fileViewer.draft"
    ></display-file-modal>
    <upload-file-modal
      v-if="uploadWidgetActive"
      @closeModal="uploadWidgetActive = false"
      @filesUpdated="retrieveFiles"
      :draft="draft"
    ></upload-file-modal>
  </div>
</template>

<script>
import RequestHandler from "@/assets/mixins/RequestHandler";
import ProjectFile from "@/components/project/ProjectFile";
import FileSidebar from "@/components/company/files/FileSidebar";
import DisplayFileModal from "@/components/reusable/FileModal";
import UploadFileModal from "@/components/reusable/UploadFileModal";

const fileTypesConst = [
  { key: "application/pdf", icon: "/icons/filetypes/pdf.svg" },
  {
    key: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    icon: "/icons/filetypes/xls.svg"
  },
  {
    key: "application/json",
    icon: "/icons/filetypes/json-file.svg"
  },
  {
    key: "image/png",
    icon: "/icons/filetypes/png.svg"
  },
  {
    key: "application/zip",
    icon: "/icons/filetypes/zip.svg"
  },
  {
    key: "text/html",
    icon: "/icons/filetypes/html.svg"
  },
  {
    key: "image/jpeg",
    icon: "/icons/filetypes/jpg.svg"
  },
  {
    key: "text/csv",
    icon: "/icons/filetypes/csv.svg"
  },
  {
    key: "application/gzip",
    icon: "/icons/filetypes/zip.svg"
  },
  {
    key:
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    icon: "/icons/filetypes/ppt.svg"
  },
  {
    key: "application/x-msdos-program",
    icon: "/icons/filetypes/exe.svg"
  },
  {
    key: "application/vnd.oasis.opendocument.text",
    icon: "/icons/filetypes/odt.svg"
  },
  {
    key: "application/octet-stream",
    icon: "/icons/filetypes/e_id_logo.svg"
  }
];
export default {
  name: "ProjectFiles",
  components: { DisplayFileModal, ProjectFile, FileSidebar, UploadFileModal },
  mixins: [RequestHandler],
  props: {
    draft: Object,
    userRole: String,
    company: String
  },
  data() {
    return {
      fileTypes: [],
      uploadedFileSize: "0B",
      fileSizeWarning: false,
      uploadWidgetActive: false,
      files: [],
      filesLoaded: false,
      showSidebar: false,
      expandedFile: null
    };
  },
  methods: {
    fileDeleteHandler() {
      this.showSidebar = false;
      this.expandedFile = null;
      this.retrieveFiles();
    },
    retrieveFiles() {
      this.files = [];
      this.filesLoaded = false;
      let url;
      if (this.$store.state.companyData.isClient) {
        url =
          "notes/drafts/" +
          this.$route.params.projectId +
          "/files/client/?documents=true";
      } else {
        url =
          "notes/draft/" +
          this.$route.params.projectId +
          "/files/?documents=true";
      }
      this.apiRequest(url, "get", true).then(res => {
        this.files = res.data.documents;
        this.filesLoaded = true;
      });
    },
    calculateUploadSize() {
      if (this.$refs.companyFiles.files.length > 0) {
        let num = 0;
        this.fileSizeWarning = false;
        for (let i = 0; i < this.$refs.companyFiles.files.length; i++) {
          const file = this.$refs.companyFiles.files[i];
          num += file.size;
        }
        if (num > this.remainingSize) {
          this.fileSizeWarning = true;
        }
        let exponent;
        let unit;
        let neg = num < 0;
        let units = ["B", "kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        if (neg) {
          num = -num;
        }

        if (num < 1) {
          return (neg ? "-" : "") + num + " B";
        }

        exponent = Math.min(
          Math.floor(Math.log(num) / Math.log(1000)),
          units.length - 1
        );
        num = (num / Math.pow(1000, exponent)).toFixed(2) * 1;
        unit = units[exponent];

        this.uploadedFileSize = (neg ? "-" : "") + num + " " + unit;
      }
    },
    openSidebar(e) {
      this.showSidebar = true;
      this.expandedFile = e;
    },
    closeSidebar() {
      this.showSidebar = false;
      this.expandedFile = null;
    }
  },
  mounted() {
    this.retrieveFiles();
    this.fileTypes = fileTypesConst;
  }
};
</script>
