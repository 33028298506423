<template>
  <div
    class="flex flex-row mobile:flex-col w-full rounded-gwdhalf shadow h-full flex-grow"
  >
    <chat-sidebar
      :user-role="userRole"
      :selected-channel="selectedChannel"
      @channelSelected="handleChannelSelect"
    ></chat-sidebar>
    <div
      class="w-10/12 bg-white mobile:w-full rounded-r-xl"
      v-if="messagesLoaded"
    >
      <admin-comments
        :messages="messages.filter(x => x.chat_type === selectedChannel)"
        :draft-uuid="draftUuid"
        :channel-type="selectedChannel"
        :company="company"
        @loadComments="loadDraftMessages(false)"
        ref="chat"
      />
    </div>
  </div>
</template>

<script>
import RequestHandler from "@/assets/mixins/RequestHandler";
import AdminComments from "@/components/project/comments/AdminComments";
import ChatSidebar from "./ChatSidebar.vue";

export default {
  name: "ProjectChat",
  components: { AdminComments, ChatSidebar },
  mixins: [RequestHandler],
  props: {
    userRole: {
      type: String,
      default: ""
    },
    draftUuid: {
      type: String,
      default: ""
    },
    company: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      messages: [],
      messagesLoaded: false,
      selectedChannel: this.$store.state.companyData.isClient ? 3 : 2,
      uploadInProgress: false,
      chatPosTimeout: null
    };
  },
  methods: {
    loadDraftMessages(full) {
      if (full) {
        this.messagesLoaded = false;
        this.messages = [];
      }
      let url;
      if (this.$store.state.companyData.isClient) {
        url = "notes/drafts/" + this.draftUuid + "/comments/client/";
      } else {
        url = "notes/draft/" + this.draftUuid + "/comments/";
      }
      this.apiRequest(url, "get", true).then(res => {
        this.messages = res.data;
        if (full) {
          this.messagesLoaded = true;
          this.$refs.chat.initChatPos();
          this.$refs.chat.initHeaders();
        } else {
          this.$refs.chat.initChatPos();
          this.$refs.chat.initHeaders();
        }
      });
    },
    handleChannelSelect(e) {
      this.selectedChannel = parseInt(e);
    }
  },
  watch: {
    selectedChannel() {
      if (this.messagesLoaded) this.$refs.chat.initChatPos();
    }
  },
  mounted() {
    this.loadDraftMessages(true);
  },
  computed: {
    symbolsRemaining() {
      return 1024 - this.content.length;
    }
  }
};
</script>
