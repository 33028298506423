<template>
  <div class="w-full flex flex-col items-center chat-window h-full">
    <div
      class="flex flex-col overflow-y-scroll w-full mb-auto mobile:py-3 px-3"
      :class="messages.length === 0 ? 'my-auto' : ''"
      id="chat-window"
      v-if="datesInited"
    >
      <project-comment
        v-for="(message, index) in messages"
        :key="message.uuid"
        :show-header="
          index > 0
            ? messages[index - 1].author.id !== message.author.id ||
              Date.parse(message.created_at) -
                Date.parse(messages[index - 1].created_at) >
                300000
            : true
        "
        :show-date-header="dateHeaderPoints.includes(message.uuid)"
        :time-diff="
          index > 0
            ? Date.parse(message.created_at) -
              Date.parse(messages[index - 1].created_at)
            : 0
        "
        :comment="message"
        :company="company"
        :id="`comment-${message.uuid}`"
      ></project-comment>
      <div
        v-if="messages.length === 0"
        class="p-5 font-medium shadow bg-offwhite rounded-gwdfull w-4/12 mobile:w-10/12 m-auto flex justify-center"
      >
        Selles kanalis pole sõnumeid saadetud!
      </div>
    </div>
    <div
      class="flex flex-row justify-center items-center relative w-full bg-offwhite py-3 rounded-br-gwdhalf mobile:rounded-none mobile:rounded-b-gwdhalf"
    >
      <div class="flex w-full relative px-5 mobile:px-2 flex-col">
        <div class="flex relative">
          <textarea
            v-model="content"
            @keydown.enter="saveComment"
            data-cy="commentField"
            class="w-full h-full relative focus:shadow-outline bg-white shadow"
            rows="2"
            :class="
              $v.$error && $v.content.$invalid
                ? 'shadow-dangeroutline focus:shadow-dangeroutline'
                : ''
            "
          ></textarea>
          <span class="admin-comments-symbol-count absolute">{{
            symbolsRemaining
          }}</span>
        </div>
        <div
          class="flex flex-row justify-between"
          :class="
            fileList.length > 0
              ? 'justify-between items-end mt-2'
              : 'justify-end items-center'
          "
        >
          <div class="flex flex-grow items-center" v-if="uploadInProgress">
            <clip-loader></clip-loader>
            <span class="ml-4">Faile laetakse üles...</span>
          </div>

          <div
            class="flex flex-row justify-end mobile:justify-between px-1 mt-2 ml-auto mobile:ml-0 mobile:w-full"
            v-if="!uploadInProgress"
          >
            <button
              @click="displayFileUpload = !displayFileUpload"
              :class="
                displayFileUpload ? 'new-button-danger' : 'new-button-pending'
              "
            >
              <span
                class="typcn icon"
                :class="
                  displayFileUpload ? 'typcn-times' : 'typcn-document-add'
                "
              ></span>
              <span class="label">{{
                displayFileUpload ? "Tühista" : "Lisa faile"
              }}</span>
            </button>
            <button
              @click="saveComment"
              class="new-button-green ml-3 mobile:ml-0"
            >
              <span class="typcn typcn-chevron-right icon"></span>
              <span class="label">Saada</span>
            </button>
          </div>
        </div>
        <div class="flex mt-2" v-if="displayFileUpload">
          <file-widget
            @filesUpdated="fileList = $event"
            class="bg-white rounded-xl shadow"
            ref="fileWidget"
          ></file-widget>
        </div>
      </div>
    </div>
    <div class="flex-row hidden">
      <span class="typcn typcn-attachment cursor-pointer text-2xl p-1"></span>
      <span
        class="typcn typcn-camera-outline cursor-pointer text-2xl p-1"
      ></span>
      <span class="typcn typcn-image cursor-pointer text-2xl p-1"></span>
    </div>
  </div>
</template>
<script>
import ProjectComment from "@/components/project/comments/ProjectComment";
import RequestHandler from "@/assets/mixins/RequestHandler";
import FileTypeParser from "@/assets/mixins/FileTypeParser";
import { maxLength } from "vuelidate/lib/validators";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import FileWidget from "@/components/reusable/FileWidget";
import moment from "moment";
export default {
  name: "AdminComments",
  components: { ProjectComment, ClipLoader, FileWidget },
  mixins: [RequestHandler, FileTypeParser],

  props: {
    messages: {
      type: Array,
      default: () => {
        return [];
      }
    },
    draftUuid: {
      type: String,
      default: ""
    },
    channelType: {
      type: Number,
      default: null
    },
    company: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      addAdminComment: false,
      content: "",
      fileList: [],
      uploadInProgress: false,
      dateHeaderPoints: [],
      displayFileUpload: false,
      datesInited: false
    };
  },
  mounted() {
    this.initHeaders();
  },
  methods: {
    selectFile() {
      let fileInputElement = this.$refs.file;
      fileInputElement.click();
    },
    updateFiles() {
      this.fileList = [...this.$refs.file.files];
    },
    saveComment() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const data = new FormData();
        data.append("content", this.content);
        data.append("channel_type", this.channelType);
        if (this.channelType === 1) {
          data.append("visible_to_workers", "false");
          data.append("visible_to_managers", "true");
        } else if (this.channelType === 2) {
          data.append("visible_to_managers", "true");
          data.append("visible_to_workers", "true");
        } else if (this.channelType === 3) {
          data.append("visible_to_client", "true");
        }
        if (this.fileList.length > 0) {
          this.uploadInProgress = true;
          for (let i = 0; i < this.fileList.length; i++) {
            const file = this.fileList[i];
            const fileToAppend = new File([file], file.name, {
              type: file.type
            });
            data.append("files", fileToAppend, file.name);
          }
        }
        let url;

        if (this.$store.state.companyData.isClient) {
          url = "notes/drafts/" + this.draftUuid + "/comments/add/client/";
        } else {
          url = "projects/" + this.draftUuid + "/comments/new/";
        }
        this.apiRequest(url, "post", true, data).then(res => {
          if (res.status === 201) {
            if (this.fileList.length > 0) {
              this.uploadInProgress = false;
              this.fileList = [];
              this.displayFileUpload = false;
              this.$refs.fileWidget.reset();
            }
            this.content = "";
            this.$emit("loadComments");
            let self = this;
            setTimeout(function() {
              self.initHeaders();
            }, 150);
          }
        });
      }
    },
    initChatPos() {
      this.$nextTick(() => {
        const lastComment = this.messages[this.messages.length - 1];
        document.getElementById(`comment-${lastComment.uuid}`).scrollIntoView();
      });
    },
    parseFileTypeIcon(file) {
      return this.parseUploadedFileType(file);
    },
    initHeaders() {
      this.datesInited = false;
      const datesProcessed = [];
      this.dateHeaderPoints = [];
      let tempMessages = this.messages;
      while (tempMessages.length > 0) {
        this.dateHeaderPoints.push(tempMessages[0].uuid);
        datesProcessed.push(
          moment(tempMessages[0].created_at).format("DD-MM-YYYY")
        );
        tempMessages = tempMessages.filter(
          x =>
            !datesProcessed.includes(moment(x.created_at).format("DD-MM-YYYY"))
        );
      }
      this.datesInited = true;
    },
    removeFile() {
      this.fileList.splice(this.fileList.findIndex());
    }
  },
  computed: {
    symbolsRemaining() {
      return 1024 - this.content.length;
    }
  },
  watch: {
    channelType() {
      this.initHeaders();
    }
  },
  validations: {
    content: {
      maxLength: maxLength(1024)
    }
  }
};
</script>
<style lang="scss">
.chat-window {
  min-height: 55vh;
  max-height: 75vh;
}
.admin-comments-symbol-count {
  right: 1%;
  top: 60%;
  @apply text-lightgrey;
}
@media screen and (max-width: 992px) {
  .chat-window {
    min-height: 80vh;
    max-height: 100vh;
  }
}
</style>
