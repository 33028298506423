<template>
  <div class="flex flex-col">
    <div class="flex flex-col mx-auto w-full justify-center py-4 mobile:px-0">
      <div class="flex">
        <div class="flex w-1/2 justify-center mobile:text-center">
          <h4 class="self-center">Kõik töötajad</h4>
        </div>
        <div class="flex w-1/2 justify-center mobile:text-center">
          <h4 class="self-center">Projektile määratud töötajad</h4>
        </div>
      </div>
      <div class="flex">
        <div
          class="flex flex-col w-1/2 pr-3 mobile:mr-0 bg-white p-2 border-r border-offwhite"
        >
          <transition-group name="list">
            <project-worker-card
              v-for="item in companyWorkers.filter(
                x => !workersSelected.includes(x.id)
              )"
              :key="item.id"
              :data="item"
              :selected="workersSelected.includes(item.id)"
              :can-edit="canEdit"
              @toggleSelect="toggleWorker"
              :class="companyWorkers.length > 1 ? 'mb-2' : ''"
            ></project-worker-card>
          </transition-group>
          <div class="flex flex-col" v-if="companyWorkers.length === 0">
            <div class="flex flex-col items-center justify-center">
              <img src="/favicon.ico" class="h-16 w-16 mb-2" />
              <span class="mb-2">Firmale pole lisatud töötajaid!</span>
              <router-link to="/workers/new">
                <button class="new-button-green">
                  <span class="icon typcn typcn-plus"></span>
                  <span class="label">Lisa töötaja</span>
                </button>
              </router-link>
            </div>
          </div>
        </div>
        <div
          class="flex flex-col w-1/2  mobile:ml-0 bg-white p-2 pl-3 border-l border-offwhite"
        >
          <transition-group name="list">
            <project-worker-card
              v-for="item in companyWorkers.filter(x =>
                workersSelected.includes(x.id)
              )"
              :key="item.id"
              :data="item"
              :selected="workersSelected.includes(item.id)"
              :can-edit="canEdit"
              @toggleSelect="toggleWorker"
              :class="companyWorkers.length > 1 ? 'mb-2' : ''"
            ></project-worker-card>
          </transition-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RequestHandler from "@/assets/mixins/RequestHandler";
import ProjectWorkerCard from "@/components/project/ProjectWorkerCard";
export default {
  name: "ProjectWorkerSelector",
  props: {
    workers: {
      type: Array,
      default: () => {
        return [];
      }
    },
    companyWorkers: {
      type: Array,
      default: () => {
        return [];
      }
    },
    canEdit: Boolean
  },
  data() {
    return {
      workersSelected: [],
      addingWorkers: false
    };
  },
  components: {
    ProjectWorkerCard
  },
  mixins: [RequestHandler],
  methods: {
    toggleWorker(e) {
      if (this.workersSelected.find(item => item === e) !== undefined) {
        this.workersSelected.splice(
          this.workersSelected.findIndex(item => item === e),
          1
        );
      } else {
        this.workersSelected.push(e);
      }
      this.$emit("workersUpdated", this.workersSelected);
    }
  },
  mounted() {
    this.workersSelected = this.companyWorkers
      .filter(x => this.workers.map(y => y.id).includes(x.id))
      .map(x => x.id);
  }
};
</script>
