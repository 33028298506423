<template>
  <div class="flex flex-col bg-white w-full" v-if="offersLoaded">
    <div class="flex items-center">
      <h2 class="mr-3">Pakkumised</h2>
      <button
        v-if="['R0', 'R1', 'R2'].includes(userRole)"
        @click="
          $store.state.companyData.activeCompany.subscription_permissions
            .client_portal
            ? $store.dispatch('offerViewer/openNewOfferModal', draft)
            : $store.dispatch('userData/openSubWarning')
        "
        class="alt-button-green"
      >
        <span class="typcn typcn-plus icon"></span>
        <span class="label">Lisa</span>
      </button>
    </div>
    <div class="flex flex-col py-3">
      <h4 v-if="showAddForm">Eelnevad pakkumised</h4>
      <div v-if="offersLoaded && localOffers.length === 0">
        Pakkumisi pole!
      </div>
      <offer-card
        v-for="item in offersExpanded ? localOffers : localOffers.slice(0, 3)"
        :key="item.id"
        :offer="item"
        :draft="draft"
        :active-offer-id="activeOfferId"
        :user-role="userRole"
      ></offer-card>
    </div>
    <div
      class="flex flex-col items-start mb-1"
      v-if="offersLoaded && localOffers.length > 3"
    >
      <button
        class=""
        :class="offersExpanded ? 'new-button-pending' : 'new-button-green'"
        @click="offersExpanded = !offersExpanded"
      >
        <span
          class="typcn typcn-chevron-right icon transform duration-100"
          :class="offersExpanded ? '-rotate-90' : 'rotate-90'"
        ></span>
        <span class="label"
          >Näita {{ offersExpanded ? "vähem" : offersToExpand }}</span
        >
      </button>
    </div>
  </div>
</template>

<script>
import OfferCard from "@/components/project/offer/OfferCard";
import RequestHandler from "@/assets/mixins/RequestHandler";
import moment from "moment";
import EventBus from "@/assets/mixins/EventBus";
export default {
  components: {
    OfferCard
  },
  props: {
    draft: {
      type: Object,
      default: () => {
        return {};
      }
    },
    userRole: {
      type: String,
      default: "R6"
    }
  },
  mixins: [RequestHandler],
  data() {
    return {
      showAddForm: false,
      localOffers: [],
      activeOfferId: null,
      offersLoaded: false,
      offersExpanded: false,
      createdOffer: null
    };
  },
  mounted() {
    this.loadOffers();
    EventBus.$on("updateOffers", () => {
      this.loadOffers();
    });
  },
  beforeDestroy() {
    EventBus.$off("updateOffers");
  },
  methods: {
    handleAddInvoice(e) {
      this.$emit("convertToInvoice", e);
    },
    handleOfferSave(offerId) {
      this.showAddForm = false;
      this.activeOfferId = offerId;
      this.createdOffer = offerId;
      this.$emit("newOffer");
      this.loadOffers();
    },
    handleOffersUpdated() {
      this.localOffers = [];
      this.loadOffers();
    },
    updateModal() {
      if (
        this.$store.state.offerViewer.offerModal.show &&
        this.$store.state.offerViewer.offerModal.data
      ) {
        this.$store.dispatch("offerViewer/openOfferModal", {
          offer: this.localOffers.find(
            x => x.id === this.$store.state.offerViewer.offerModal.data.offer.id
          ),
          draft: this.draft
        });
      }
    },
    sortOffers(arr) {
      this.localOffers = arr.sort((a, b) => {
        if (moment(a.created_at).unix() > moment(b.created_at).unix()) {
          return -1;
        } else if (moment(a.created_at).unix() < moment(b.created_at).unix()) {
          return 1;
        } else {
          return 0;
        }
      });
    },
    loadOffers() {
      if (this.$store.state.companyData.isClient) {
        this.apiRequest(
          "notes/draft/" + this.$route.params.projectId + "/offers/client/",
          "get",
          true
        ).then(async res => {
          await this.sortOffers(res.data);
          this.updateModal();
          this.offersLoaded = true;
        });
      } else {
        this.apiRequest(
          "notes/draft/" + this.draft.uuid + "/offers/",
          "get",
          true
        ).then(async res => {
          await this.sortOffers(res.data);
          this.updateModal();
          if (this.createdOffer) {
            this.$store.dispatch("offerViewer/openOfferModal", {
              offer: this.localOffers.find(x => x.id === this.createdOffer),
              draft: this.draft
            });
          }
          this.offersLoaded = true;
        });
      }
    }
  },
  computed: {
    offersToExpand() {
      return this.offersLoaded
        ? `kõiki (${parseInt(this.localOffers.length - 3)})`
        : "";
    }
  }
};
</script>
