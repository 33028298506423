<template>
  <div class="flex flex-col w-full h-full">
    <div class="flex flex-col mobile:p-0 h-full map-container" v-if="location">
      <div
        class="flex w-full justify-center bg-offwhite rounded-t-xl mobile:rounded-xl items-center leading-none py-1 px-2 shadow"
      >
        <a
          :href="
            'https://maps.google.com/?q=' +
              location.latitude +
              ',' +
              location.longitude
          "
          target="_blank"
          class="flex items-center justify-center"
        >
          <span
            class=" bg-green rounded-full p-1 hover:bg-darkgreen duration-75 h-8 w-8 flex items-center justify-center"
          >
            <img
              src="/bc21/placeholder.svg"
              class="filter-to-white h-5 w-5"/></span
        ></a>

        <div class="flex flex-auto justify-center">
          <h4 class="flex ml-1" v-if="location && mapInited">
            {{ location.long_address }}
          </h4>
          <h3 class="flex ml-1" v-if="!location">Täitmata</h3>
        </div>
      </div>
      <div
        class="flex h-full w-full shadow rounded-b-xl mobile:hidden"
        v-if="mapInited"
      >
        <l-map
          class="h-full w-full z-10 rounded-b-xl"
          :options="mapOptions"
          :center="mapOptions.center"
          :interactive="false"
        >
          <l-tile-layer
            :url="tileUrl"
            :attribution="'© OpenStreetMap contributors'"
            :position="'bottomleft'"
            class="h-full w-full justify-start flex"
          ></l-tile-layer>
          <l-marker :lat-lng="[location.latitude, location.longitude]">
            <l-icon
              icon-url="/bc21/placeholder.svg"
              :icon-size="dynamicSize"
            ></l-icon>
          </l-marker>
        </l-map>
      </div>
    </div>
    <div class="flex" v-else>
      Määramata
    </div>
  </div>
</template>

<script>
import { LMap, LTileLayer, LMarker, LIcon } from "vue2-leaflet";
import { latLng } from "leaflet";
import RequestHandler from "@/assets/mixins/RequestHandler";

export default {
  components: {
    LMap,
    LMarker,
    LIcon,
    LTileLayer
  },
  data() {
    return {
      tileUrl: "https://{s}.tile.osm.org/{z}/{x}/{y}.png",
      mapOptions: {
        zoom: 6,
        center: latLng(58.5953, 25.0136),
        interactive: false
      },
      mapInited: false
    };
  },
  mixins: [RequestHandler],
  props: {
    location: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  methods: {
    initLocation() {
      if (this.location) {
        this.mapOptions.zoom = 18;
        this.mapOptions.center = latLng(
          this.location.latitude.substring(0, 2) +
            "." +
            this.location.latitude.substring(3),
          this.location.longitude.substring(0, 2) +
            "." +
            this.location.longitude.substring(3)
        );
        this.mapInited = true;
      } else {
        this.mapInited = true;
      }
    }
  },
  computed: {
    dynamicSize() {
      return [this.iconSize, this.iconSize * 1.15];
    }
  },
  mounted() {
    this.initLocation();
  }
};
</script>

<style lang="scss">
.map-container {
  min-height: 325px;
}
@media screen and (max-width: 992px) {
  .map-container {
    min-height: auto;
  }
}
</style>
