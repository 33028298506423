var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex-col flex w-full"},[(_vm.showDateHeader)?_c('div',{staticClass:"self-center w-full flex flex-col justify-center pt-2 pb-3"},[_c('span',{staticClass:"px-2"},[_vm._v(_vm._s(_vm.moment(_vm.comment.created_at).format("DD.MM.YYYY")))]),_c('span',{staticClass:"h-1 w-full bg-offwhite rounded-xl"})]):_vm._e(),_c('div',{staticClass:"flex w-full mb-2",class:[
      _vm.comment.author.id === _vm.$store.state.userData.user.id
        ? 'justify-end'
        : 'justify-start'
    ]},[_c('div',{staticClass:"flex flex-col max-w-full mobile:w-full"},[(
          _vm.comment.author.id === _vm.$store.state.userData.user.id && _vm.showHeader
        )?_c('div',{staticClass:"flex flex-row w-full items-center justify-end mb-1"},[_c('div',{staticClass:"flex items-center bg-white p-1"},[_c('span',{staticClass:"text-left px-3 font-semibold"},[_vm._v(_vm._s(_vm.comment.author.display_username)+" ")]),_c('span',{staticClass:"flex text-center disabled"},[_vm._v(_vm._s(_vm.parseDate))])])]):_vm._e(),(
          _vm.comment.author.id !== _vm.$store.state.userData.user.id && _vm.showHeader
        )?_c('div',{staticClass:"flex flex-row w-full items-center justify-start"},[_c('span',{staticClass:"flex text-center disabled"},[_vm._v(_vm._s(_vm.parseDate))]),_c('a',{staticClass:"text-left px-3 font-semibold",attrs:{"href":'/profile/' + _vm.comment.author.id,"target":"_blank"}},[_vm._v(_vm._s(_vm.comment.author.display_username)+" "),_c('span',{staticClass:"flex text-center hover:text-green cursor-pointer",on:{"click":function($event){return _vm.$router.push('/profile/' + _vm.author.id)}}})])]):_vm._e(),(_vm.comment.content.length > 0)?_c('div',{staticClass:"flex flex-col rounded-xl py-2 px-3",class:[
          _vm.comment.author.id === _vm.$store.state.userData.user.id
            ? 'bg-offwhite'
            : 'bg-offwhite',
          _vm.comment.documents.length > 0 || _vm.commentFiles.length > 0
            ? 'mb-1'
            : ''
        ]},[(_vm.comment.status === 1)?_c('span',{staticClass:"break-words",class:_vm.comment.author.id === _vm.$store.state.userData.user.id ? '' : ''},[_vm._v(_vm._s(_vm.comment.content))]):_vm._e(),(_vm.comment.status === 2)?_c('span',[_vm._v("Kommentaar kustutatud")]):_vm._e()]):_vm._e(),(_vm.comment.documents.length > 0)?_c('div',[_c('div',{staticClass:"flex flex-wrap"},_vm._l((_vm.comment.documents.filter(function (x) { return ['image/png', 'image/jpeg', 'image/svg+xml'].includes(
                x.file_type
              ); }
            )),function(image){return _c('project-image',{key:image.id,attrs:{"image":image,"type":'comment_image',"company-uuid":_vm.company},on:{"active":_vm.activateImage}})}),1),(_vm.commentFiles.length > 0)?_c('div',[_c('div',{staticClass:"flex flex-col"},_vm._l((_vm.commentFiles),function(file){return _c('comment-file',{key:file.id,attrs:{"file":file,"company":_vm.company}})}),1)]):_vm._e()]):_vm._e()]),(_vm.activeImage)?_c('image-modal',{attrs:{"image-url":_vm.activeImage,"img-array":_vm.comment.documents.filter(function (x) { return ['image/png', 'image/jpeg', 'image/svg+xml'].includes(x.file_type); }
        )}}):_vm._e(),(_vm.activeImage)?_c('div',{staticClass:"modal overflow-x-hidden",on:{"click":function($event){_vm.activeImage = null}}},[_c('div',{staticClass:"image-modal overflow-x-hidden cursor-pointer"},[_c('img',{staticClass:"modal-image border-2 border-green",attrs:{"src":_vm.activeImage}}),_c('div',{staticClass:"close-button leading-none bg-danger flex justify-center items-center",on:{"click":function($event){_vm.activeImage = null}}},[_c('span',{staticClass:"text-4xl typcn typcn-times leading-none"})])])]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }